import React, {useState, useEffect} from 'react';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

import { Swiper, SwiperSlide } from 'swiper/react';
/** Swiper***/
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
//import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

import SwiperCore, {
    EffectCoverflow, Pagination,Navigation
  } from 'swiper/core';

  SwiperCore.use([EffectCoverflow,Pagination,Navigation]);

const List = ({ match, saludo, subtitulo}) => {

  // const { path } = match; 

    let [data, setData] = useState([])
    
    useEffect(() => {
        getData()
    }, []);

    async function getData(){
      const response = await fetchConToken(`Imagenes/Imagenes?pagina=cliente`);
      setData(response);
    }

    const eliminarImagen = (file) => {
      fetchConToken(`Imagenes/Imagenes?imagen=${file}`,null,"DELETE")   
        .then(imagen => {
            alertService.success('Imagen eliminada.', { keepAfterRouteChange: true })
            getData()
        })
        .catch(alertService.error);         
    }

    const onFileChange = ({target}) => {
        // const { id,name, value } = target;
        if(target.files[0] != null && target.files[0].name != null){
            // const imgs = target.files[0].name.split(".");
  
            const formData = new FormData();
  
            formData.append("tipo",'escritorio');
            formData.append("Pagina",'cliente');
            
            formData.append(
            "FileToUpload",
            target.files[0],
            target.files[0].name
            );
            fetchConToken("Imagenes/Imagenes",formData,"POST",true)
            .then(imagen => {
                getData();
                alertService.success('Imagenes cargadas.', { keepAfterRouteChange: true });
            })
        }
    }

    return (
        <>
 <div className="container-fluid" >
            <div className='col-md-12' style={{textAlign: "right"}}>
            <input type="file" 
                               className="form-control" 
                               name="" 
                               multiple={false}
                               onChange= {onFileChange}
                               style={{width: "315px"}}
                               />
            </div>
            <label>Tamaño Aprox. recomendado: (1920 x 696)px</label>
            <br/>
            <div className="card col-12 col-sm-12 col-md-12" style={{overflowX: "auto",maxWidth: "800px"}}>
              <div className='col-md-12 row' >
                <div className='col-md-12'>
                <div className='text-center'>
                  
                   <Swiper
                                spaceBetween={2}
                                navigation={true}
                                centeredSlides={false}
                                slidesPerView={1} 
                                pagination={false}
                                className="Swiperprincipal2"
                            >
                                {
                                        data.map(items=>{
                                        return  <SwiperSlide key={items.id} ><div>
                                                    <button type='button' onClick={()=>eliminarImagen(items.ruta)} className="modal-close btn btn-outline-danger" style={{color: "red",border: "none",top: "0",right: "0",padding: "0"}}>
                                                        X
                                                    </button>
                                                 <img src={items.ruta} alt=''/>
                                                </div>
                                                </SwiperSlide>
                                        }) 
                                }
                    </Swiper>
                </div>
              </div>
            </div>
          </div>
          </div>
        </>
    );    


}


export { List };