import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';


const AddEdit = ({ history, match}) => {    

    const path = match.url;
    //console.log(sucursales);
    const { id } = match.params;
    const isAddMode = !id;
     
    

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

    const { register, handleSubmit, reset } = useForm();

    function onSubmit(data) {
        return isAddMode
            ? createReg(data)
            : updateReg(id, data);
    }

    const [empresa, setempresa] = useState(getDataSession('empresa'));
    const [sucursal, setsucursal] = useState(getDataSession('sucursal'));

    // function createUser(data) {
    //     return userService.create(data)
    //         .then(() => {
    //             alertService.success('User added', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }

    function createReg(data) {
        return fetchConToken("catalogos/etiquetas",data,"POST")
            .then(() => {
                alertService.success('Etiqueta agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateReg(id, data) {
        return fetchConToken("catalogos/etiquetas", data,"PUT")
            .then(() => {
                alertService.success('Etiqueta modificado', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const [data, setData] = useState({excluir_Generar: "S",excluir_Redimir:"S"});

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("catalogos/etiquetas?id="+id).then(dep => {
                setData(dep);
                setempresa(dep.empresa_id);
                setsucursal(dep.sucursal_id);
            });
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(data);
            // eslint-disable-next-line
        }, [data]);


        const onChangeCheck = ({target}) => {
            const xCheck = target.checked ? 'S' : 'N';
            setData({
              ...data,
              [target.name]: xCheck
            });
          }
          
        function handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
        
            setData({
              ...data,
              [name]: value
            });
        } 

    return (
        <>
        <div className="container-fluid" >
         <form  onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                  <h3>{isAddMode ? 'Agregar etiqueta' : 'Editar etiqueta'}</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                  </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp; 
                  </Link>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nombre" 
                        placeholder="nombre" 
                        required={true}
                        maxLength={100}
                        value={data.nombre}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
            </div>
          </div>
          </div>

          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">

                    <div className="form-check form-switch">
                        <input type="checkbox" 
                        className="form-check-input" 
                        name="excluir_Generar" 
                        role="switch" 
                        onChange={ onChangeCheck }
                        checked={ data.excluir_Generar === 'S' }
                        />
                        <label htmlFor="excluir_Generar">¿Excluir para generar?</label>
                    </div>

                    <div className="form-check form-switch">
                        <input type="checkbox" 
                        className="form-check-input" 
                        name="excluir_Redimir" 
                        role="switch" 
                        onChange={ onChangeCheck }
                        checked={ data.excluir_Redimir === 'S' }
                        />
                        <label htmlFor="excluir_Redimir">¿Excluir para redimir?</label>
                    </div>

                    <div className="col-md-4">
                        <input type="text" className="form-control" placeholder="empresa" value={empresa} {...register("empresa_id", {required: true, maxLength: 10})} style={{display: 'none' }}/>
                        <input type="text" className="form-control" placeholder="sucursal" value={sucursal} {...register("sucursal_id", {required: true, maxLength: 10})} style={{display: 'none'}}/>
                    </div>

                </div>
                <div>
            </div>
          </div>
          </div>

          </div> 
          </form>
        </div>
        </>
    );    


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export {AddEdit};