//overview: '/overview',
const SLUGS=  {
    // private
    dashboard: '/dashboard',
    puntos: '/program/points',
    referidosp: '/program/referred',
    notificacionp: '/program/notification',
    actividadp: '/program/activity',
    ventasp: '/program/orders',
    // clientes: '/customers',
    roles: '/roles',
    estadisticas: '/performance',
    sucursal: '/settings/branch',
    users: '/settings/users',
    ideasThree: '/settings/notification',
    departamentos: '/settings/department',
    categorias: '/settings/category',
    etiquetas: '/settings/labels',
    articulos: '/settings/articles',
    promociones: '/promotions',
    recompensas: '/rewards',
    campanias: '/campaign',
    settings: '/settings',
    subscription: '/subscription',
    slider: '/slider',
    sliderCliente: '/sliderCliente',
    
    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgotpassword',
    resetpassword: '/resetpassword',
    validarcorreo: '/validarcorreo',
    //home
    clientes: '/clientes',

    home: '/'

    
};

export default SLUGS;
