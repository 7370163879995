import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken,fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import  Pagination  from 'components/pagination/Pagination'

import * as FileSaver from "file-saver";
const List = ({ match, saludo, subtitulo}) => {

  const { path } = match;


    let [data, setData] = useState([])
    // eslint-disable-next-line
    const [totalxPagina,setTotalxPagina] = useState(10);
    //const [busqueda, setbusqueda] = useState("")

    async function getData(page){
      const response = await fetchConToken(`canjes/lista?empresaid=${getDataSession('empresa')}&tipo=T&pagina=${page}&totalxpagina=${totalxPagina}`);
      setData(response);
    }

    const exportToCSV = () => {
      fetchConTokenFile(`Exportar/Excel?empresaid=${getDataSession('empresa')}&tipo2=T&pagina=1&totalxpagina=${totalxPagina}&tipo=CANJES&columnas=id,articulo_Id,fechaInicio,fechaFin,estatus,monto_Requerido,puntos_Requeridos,canjes_Maximos,num_Canjes`,null,'GET',true)
      .then(function(response) {
        return response.blob();
      }).then(function(blob) {
        FileSaver.saveAs(blob, 'canjes.csv');
      })
    }

    React.useEffect(()=>{
      getData(1);
      // eslint-disable-next-line
    }, []);

  
    function eliminar(id){
      fetchConToken(`Canjes?canjeId=${id}`,null,'DELETE');
    }

    return (
        <>
         <div className="container-fluid" >
         <div className="row row mb-3" >
         <div className="col-12 col-sm-12 col-md-12" style={{textAlign: "right"}}>
                <Link to={`${path}/add`} className="btn btn-outline-success"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  &nbsp;
                  Nuevo 
                </Link>
                &nbsp;
                <button className="btn btn-write" onClick={(e) => exportToCSV()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16" style={{color: "#016e38"}}>
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z"/>
                  </svg>
                  &nbsp;
                  Descargar 
                </button>
                &nbsp;
              </div>
            </div>
            <div className=" col-12 col-sm-12 col-md-12">
            <div className="card table-responsive">
              <div className="table-responsive">
              <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Articulo</th>
                      <th>FechaInicio</th>
                      <th>FechaFin</th>
                      <th>Estatus</th> 
                      <th>MontoRequerido</th> 
                      <th>PuntosRequeridos</th>
                      <th>CanjesMáximos</th>
                      <th>Num_Canjes</th>
                      <th>Editar</th>
                      <th>Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => {
                    return <tr key={item.id} >
                            <td>{item.id}</td>
                            <td>{item.articulo_Id}</td>
                            <td>{item.fechaInicio.substring(0,10)}</td>
                            <td>{item.fechaFin.substring(0,10)}</td>
                            <td>{item.estatus}</td>
                            <td>{item.monto_Requerido}</td>
                            <td>{item.puntos_Requeridos}</td>
                            <td>{item.canjes_Maximos}</td>
                            <td>{item.num_Canjes}</td>
                            <td>
                            <Link to={`${path}/edit/${item.id}`} className="btn  mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </Link>
                            </td>
                            <td>
                              <button className='btn btn-write' type='button' onClick={()=>eliminar(item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-archive-fill" viewBox="0 0 16 16">
                                  <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
                                </svg>
                            </button>
                            </td>
                     </tr>
                     })}
                  </tbody>
                </table>
                <Pagination consumirPaginacion={getData} elementos={data.length} totalxPagina={totalxPagina}/>
              </div>
            </div>
            </div>
        </div>
        </>
    );    


}

List.propTypes = {
    saludo: PropTypes.string
}

List.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { List };