import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { fetchSinToken } from 'helpers/fetch';

import fondocolmd4 from 'images/login/Image_02.jpg';
//import logoFyttsa from 'images/login/Logo_01.png';
import logoFyttsa from 'images/home/Logo_sj.png';

import logoElefante from 'images/login/Image_01.png';

import logoFace from 'images/login/Icon_01.png';
import logoInsta from 'images/login/Icon_02.png';
import logoTwitter from 'images/login/Icon_03.png';
import logoIn from 'images/login/Icon_04.png';
import logoYou from 'images/login/Icon_05.png';

import emonedero from 'images/login/emonedero.png';
import { alertService } from '_services';

const ResetPassword = ({ history, location }) => {

    const [loading, setLoading] = useState(false);
    const password = useFormInput('');
    //const { key } = match.params;
    const query = new URLSearchParams(location.search);//.get("id");
    const key = query.get('key')

    const [error, setError] = useState(null);

    const handleLogin = () => {
      setError(null);
      setLoading(true);

      fetchSinToken("Usuarios/recuperarclave",{ key: key, clave: password.value },"POST")
        .then(response => {
          setLoading(false);
          //setUserSession(response.token, response.userName);
          alertService.success('Password modificado correctamente', { keepAfterRouteChange: true });
          history.push('/clientes');
      }).catch(error => {
          setLoading(false);
          //if (error.response.status === 401) 
          //  setError(error.response.data.message);
          //else 
            setError("Error al modificar password. Por favor, intente más tarde.");
      });
    }
    
    

    return (
      <>
     

      <div className="col-md-12 row login" style={{minHeight: "100vh",marginLeft: "0"}}>
        <div className="col-12 col-md-8" style={{display: window.innerWidth < 768 ? 'none' : 'block'}}>
          <div className="div-emonedero" >
              <img src={emonedero} alt='' />
           </div>
           <div className="text-center" style={{height: "70%"}}>
           <div className="col-md-12" style={{  position: "absolute",top: "20%",width: "60%"}}>
             <h1 style={{color: "#003869",fontSize: "3.5vw"}}><b>LA FUERZA DE</b></h1>
             <h3 style={{color: "#6F6F6E",fontSize: "2.5vw"}}>UNA GRAN FAMILIA</h3>
          </div>
              <div style={{backgroundImage: `url(${logoElefante})`, width: "100%",height: "100%",backgroundRepeat: "no-repeat",    backgroundSize: "45%,300px",backgroundPositionY: "bottom"}}>
              
                <div className="col-md-12" style={{  position: "absolute",bottom: "40%",width: "60%"}}>
                  <h4 style={{color: "#003869"}}><b>Bienvenido a eMonedero </b></h4>
                  <label style={{color: "#009CDE"}}>Control y gestion de puntos y recompensas</label>
                </div>
                <div className="col-md-12 text-center login-icons" style={{  position: "absolute",bottom: "30px",width: "60%"}}>
                  <img src={logoFace} alt=''/>   &nbsp;
                  <img src={logoInsta} alt=''/>   &nbsp;
                  <img src={logoTwitter} alt=''/>   &nbsp;
                  <img src={logoIn} alt=''/>   &nbsp; 
                  <img src={logoYou} alt=''/>  &nbsp;
                </div>
              </div>
              

              
           </div>
        </div>
        <div className="col-12 col-md-4" style={{backgroundImage: `url(${fondocolmd4})`}}>
        <form>
            
              <div>
              <div className="col-md-12 text-center">
              <img src={logoFyttsa} className="img-fyt" alt='' />
              <h2 style={{color: "white"}}><b>Reseteo de Password</b></h2>
              <label style={{color: "white"}}>Capture su nuevo password</label>
           </div>
           <br/>
           <div className="col-md-12">
             <div className="col-md-10 container">
              <label style={{color: "white"}}><b>Contraseña</b></label>
                 <div className="input-group">
                   <span className="fa fa-lock"></span>
                   <input 
                                     type="password" {...password} 
                                     className="form-control" 
                                     autoComplete="new-password" 
                                     placeholder="Clave" />
                 </div>
             </div>
           </div>
           <br/>                                              
           <div className="card-footer text-center">
             {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
             <input style={{color: "white", borderRadius: "30px"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Enviar'} onClick={handleLogin} disabled={loading} /><br />
             <a href="/login" className="text-center" style={{color: "white"}}> ¿Ya tienes tus datos?</a>                                
           </div>
           </div>
            
            
         </form>
        </div>
      </div>
      </>
    );
}



const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}


export { ResetPassword };