import React, { useState, useEffect }  from 'react';
import HeaderComponentLanding from 'components/home/HeaderComponentLanding'
import SuscripcionComponent from 'components/home/SuscripcionComponent'
import FooterComponent from 'components/home/FooterComponent'

import { fetchSinToken } from 'helpers/fetch';

import { Swiper, SwiperSlide } from 'swiper/react';
/** Swiper***/
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
//import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

import SwiperCore, {
  EffectCoverflow, Pagination,Navigation,Autoplay
} from 'swiper/core';
SwiperCore.use([EffectCoverflow,Pagination,Navigation,Autoplay]);


const Clientes = (props) => {

    let [puntos, setPuntos] = useState([])
    let [data, setData] = useState([])

    async function getData(){
      const response = await fetchSinToken(`Imagenes/Imagenes?pagina=cliente`);
      setData(response);
    }

    useEffect(() => {
          fetchSinToken("Configuraciones/Conceptos?empresaid=1&sucursalid=1&clave=G002")
          .then(data => { 
            if(data != null && data.subConceptos != null)
            {
              let subconcepto = data.subConceptos.filter(sub => sub.clave_Concepto === "G002");
              if(subconcepto.length > 0){
                setPuntos(subconcepto[0].puntos)
              }
            }

          })

          getData()
  }, []);

 
    return (
    <div className="App">
      <div className="header-div">
      <HeaderComponentLanding/>
      </div>
      <>
      <Swiper
        spaceBetween={1}
        navigation={false}
        centeredSlides={true}
        //slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
        slidesPerView={1}
        loop={true}
        speed={1200}
        pagination={{
          clickable: true
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        className="SwiperCliente"
      >
        { data.map(items=>{
            return  <SwiperSlide key={items.id} ><img src={items.ruta} alt='' /></SwiperSlide>
            }) 
        }
      </Swiper>
      </>
      <br/>
      <SuscripcionComponent puntos = {puntos}/>
      <br/>
      <br/>
      <FooterComponent/>
    </div>
  );
}
export { Clientes };

