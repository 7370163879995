import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Moment from 'moment';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';



const AddEdit = ({ history, match}) => {    

    const path = match.url;
    //console.log(sucursales);
    const { id } = match.params;
    const isAddMode = !id;
    
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const busqueda = useFormInput('');
    //const [page, setPage] = useState(1);
    const [clientes, setClientes] = useState([]);

    async function buscarCliente(pagina) {
        const response = await  fetchConToken(`usuarios/Lista?pagina=${pagina}&totalxPagina=10&rolId=3&empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&busqueda=${busqueda.value}`)
        setClientes(response);

    }

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

    // eslint-disable-next-line
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    
    //console.log(errors);

    function onSubmit(data) {
        data.detalles = detalles
        data.estatus= "A"
        data.empresa_Id= getDataSession('empresa')
        data.sucursal_Id= getDataSession('sucursal')
        data.fecha = fecha
        return isAddMode
            ? createMov(data)
            : updateMov(id, data);
    }

    const [movimiento, setMovimiento] = useState({});
    // const [empresa, setEmpresa] = useState(getDataSession('empresa'));
    // const [sucursal, setSucursal] = useState(getDataSession('sucursal'));
    const [fecha, setfecha] = useState(new Date().toISOString().substr(0,10))
    const [detalles, setdetalles] = useState([])
    
    //const d1 = new Date(cfg.fecha_Vigencia);
    //        setfechaVigencia(d1.toISOString().substr(0,10));

    // function createUser(data) {
    //     return userService.create(data)
    //         .then(() => {
    //             alertService.success('User added', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }

    function createMov(data) {
        
        return fetchConToken("usuarios/movimientos",data,"POST")
            .then(() => {
                alertService.success('Movimiento agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateMov(id, data) {
        return fetchConToken("usuarios/movimientos", data,"PUT")
            .then(() => {
                alertService.success('Movimiento modificado', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("usuarios/movimientos?id="+id+"&empresaid="+getDataSession('empresa')+"&sucursalid="+getDataSession('sucursal')).then(mov => {
                mov.fecha = Moment(mov.fecha).format('YYYY-MM-DD')
                setMovimiento(mov);
                setdetalles(mov.detalles);

                //new Date(new Date().getFullYear(),new Date().getMonth(), 1).toISOString().substr(0,10);

            }).catch( alertService.error);
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(movimiento);
            // eslint-disable-next-line
        }, [movimiento]);

        const onChangeDate = (date) => setfecha(date.target.value);

        function elejirCliente(clienteId, nombre) {
            var det=  {
                "id": 0,
                "movimiento_Id": 0,
                "usuario_Id": clienteId,
                "usuario": nombre,
                "puntos_Asignados": 0
              }

            detalles.push(det)
        }

        const handleChange = (e) => {
            const { id,name, value } = e.target;
            setdetalles( detalles.map(detalle => (
                //Si agregamos un '=' el field deja de cambiar
                // eslint-disable-next-line
                detalle.usuario_Id == id ? {...detalle, [name]: value}: detalle
            )))
    
        };

    return (
        <> 
        <div className="container-fluid" >
        <form  onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
               <div className="col-md-6" style={{textAlign: "left"}}>
                  <h3>{isAddMode ? 'Agregar actividad' : 'Ver actividad'}</h3>
                </div>
                <div className="col-md-6" style={{textAlign: "right"}}>
                {isAddMode ? 
                <button className="btn btn-outline-success" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                   </button>
                   :''}
                   &nbsp;
                   <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                            <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                        </svg> 
                        &nbsp;
                        Regresar
                   </Link>
                </div>
            </div>                  
          </div>
         
                <div className="card fs-6">
                    

                    <div className="card-body">
                <div className="row col-md-12">

                    <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="empresa" value="1" {...register("empresa_id", {required: true, maxLength: 80})} style={{display: 'none'}}/>
                        <input type="text" className="form-control" placeholder="sucursal" value="1" {...register("sucursal_id", {required: true, maxLength: 80})} style={{display: 'none'}}/>
                        <label htmlFor="fecha" className="form-label">Fecha</label>
                        <input type="date" className="form-control"  {...register("fecha", {required: true, maxLength: 100})}   onChange={ onChangeDate } required format='yyyy-MM-dd'/>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="fecha" className="form-label">Descripción</label>
                        <input type="text" className="form-control" placeholder="descripcion" {...register("descripcion", {required: true, maxLength: 200})}/>
                    </div>
                    <div className="col-md-12">
                        <br/>
                    <button type="button" className="btn btn-outline-info"  onClick={openModal}>Agregar Cliente</button>
                    <div className="card mt-2">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                <th>Cliente</th>
                                <th>Puntos</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {detalles.map(item => {
                                return  <tr key={item.id}>
                                <td>{item.usuario}</td>
                                <td><input type="number" id={item.usuario_Id} className="form-control" value={item.puntos_Asignados} name="puntos_Asignados" onChange={handleChange}/></td>
                                <td></td>
                                </tr>

                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </form>
        </div>

        <Modal isOpen={isOpenModal} closeModal={closeModal}>
          <br/>
          <div className="row">
            <div className="col-md-2 tex-left">
                <label>Buscar</label>
            </div>
            <div className="col-md-8 ">
                <input type="text" className="form-control" {...busqueda} />
            </div>
            <div className="col-md-2 text-left">
                <button type="button" className="btn btn-outline-secondary" onClick={()=>buscarCliente(1)} style={{border: "none"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
                </button>
            </div>
          </div>
        <table className="table">
                        <thead>
                            <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>telefono</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        clientes.map(item=>{
                          return <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.nombre+ " " + item.apellidop+ " "+item.apellidom}</td>
                                    <td>{item.correo}</td>
                                    <td>{item.telefono}</td>
                                    <td>
                                        <button type="button" className="btn btn-outline-info" onClick={()=>elejirCliente(item.id, item.nombre+ " " + item.apellidop+ " "+item.apellidom)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                        })
                        }
                        </tbody> 
        </table>
      </Modal>
        </>
    );    


}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
     
    }
    return {
      value,
      onChange: handleChange
    }
  }


export {AddEdit};