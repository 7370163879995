import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
// import Pagination from 'components/pagination/Pagination'
import { alertService } from '_services';

import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"

const List = ({ match, saludo, subtitulo }) => {

  const { path } = match;

  let [data, setData] = useState([])
  let [dataDisct, setDataDisct] = useState([])

  let [count, setCount] = useState(0)

  let [fileE, setFileE] = useState(null)
  let [fileT, setFileT] = useState(null)
  let [fileM, setFileM] = useState(null)

  let [mensajeE, setMensajeE] = useState(null)
  let [mensajeT, setMensajeT] = useState(null)
  let [mensajeM, setMensajeM] = useState(null)


  const [isOpenModal, openModal, closeModal] = useModal(false);

  async function getData() {
    const response = await fetchConToken(`Imagenes/Imagenes?pagina=home`);
    setData(response);

    const uniqueTags = [];
    response.forEach(img => {
      if (uniqueTags.indexOf(img.id) === -1) {
        uniqueTags.push(img.id)
      }
    });
    const arrayInt = uniqueTags.map((i) => parseInt(i));
    setDataDisct(uniqueTags);
    var ValorMAx = Math.max.apply(Math, arrayInt);
    if (arrayInt.length !== 0) {
      setCount(Number.isInteger(ValorMAx) && ValorMAx + 1);
    } else { setCount(1) }
    // var ValorMAx = Math.max.apply(Math,arrayInt);
    // console.log("ValorMAx + " + ValorMAx);
    // setCount(isNumeric(ValorMAx) ? ValorMAx+1 : 1);
  }

  // function isNumeric(number) {
  //   if (+number === +number) { // if is a number
  //     return true;
  //   }
  //   return false;
  // }

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const GuardarFile = () => {
    if (fileE == null) {
      setMensajeE('Seleccione una imagen para escritorio')
      return
    }
    if (fileT == null) {
      setMensajeT('Seleccione una imagen para tablet')
      return
    }
    if (fileM == null) {
      setMensajeM('Seleccione una imagen para movil')
      return
    }

    fetchConToken("Imagenes/Imagenes", fileE, "POST", true)
      .then(imagen => {
        setMensajeE(null)
      })
      .catch(alertService.error);

    fetchConToken("Imagenes/Imagenes", fileT, "POST", true)
      .then(imagen => {
        setMensajeT(null)
      })
      .catch(alertService.error);

    fetchConToken("Imagenes/Imagenes", fileM, "POST", true)
      .then(imagen => {
        setMensajeM(null)
        getData();
      })
      .catch(alertService.error);


    closeModal();
    alertService.success('Imagenes cargadas.', { keepAfterRouteChange: true });

  }


  const onFileChangeE = ({ target }) => {
    // const { id, name, value } = target;
    if (target.files[0] != null && target.files[0].name != null) {
      const imgs = target.files[0].name.split(".");

      const formData = new FormData();

      formData.append("tipo", 'escritorio');
      formData.append("Pagina", 'home');

      formData.append(
        "FileToUpload",
        target.files[0],
        count + "." + imgs[1]
      );
      setFileE(formData);
      //onFileChange(formData);
      //setCountE(countE+1);
    }
  }
  const onFileChangeT = ({ target }) => {
    // const { id, name, value } = target;
    if (target.files[0] != null && target.files[0].name != null) {
      const imgs = target.files[0].name.split(".");

      const formData = new FormData();

      formData.append("tipo", 'tablet');
      formData.append("Pagina", 'home');

      formData.append(
        "FileToUpload",
        target.files[0],
        count + "." + imgs[1]
      );
      setFileT(formData);
      //onFileChange(formData);
      //setCountT(countT+1);
    }
  }
  
  const onFileChangeM = ({ target }) => {
    // const { id, name, value } = target;
    if (target.files[0] != null && target.files[0].name != null) {
      const imgs = target.files[0].name.split(".");

      const formData = new FormData();

      formData.append("tipo", 'movil');
      formData.append("Pagina", 'home');

      formData.append(
        "FileToUpload",
        target.files[0],
        count + "." + imgs[1]
      );
      setFileM(formData);
    }
  }

  const eliminarImagen = (id) => {
    var aux = data.filter(d => d.id === id);

    aux.forEach(item => {
      fetchConToken(`Imagenes/Imagenes?imagen=${item.ruta}`, null, "DELETE")
      alertService.success('Imagen eliminada.', { keepAfterRouteChange: true })
      getData();
    })
  }

  return (
    <>
      <div className="container-fluid" >
        <div className='col-md-12' style={{ textAlign: "right" }}>
          <button type="button" className="btn btn-outline-info mb-3" onClick={openModal}>Agregar</button>
        </div>

        <div className="card col-12 col-sm-12 col-md-12" style={{ overflowX: "auto", maxWidth: "90vw" }}>
          <div className='col-md-12 row' >
            <div className='col-md-12'>
              <table className="table">
                <thead>
                  <tr>
                    <th>Escritorio</th>
                    <th>Tablet</th>
                    <th>Movil</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataDisct.map((item, i) => {
                      return <tr key={i}>
                        
                        <td className='text-center'>
                          {
                            <img src={data.filter(d => d.tipo === 'escritorio' && d.id === item).length > 0 ? data.filter(d => d.tipo === 'escritorio' && d.id === item)[0].ruta : ""} style={{ height: "100px", width: "auto" }} alt=''/>
                          }
                        </td>
                        <td className='text-center'>
                          {
                            <img src={data.filter(d => d.tipo === 'tablet' && d.id === item).length > 0 ? data.filter(d => d.tipo === 'tablet' && d.id === item)[0].ruta : ""} style={{ height: "100px", width: "auto" }} alt=''/>
                          }
                        </td>
                        <td className='text-center'>
                          {
                            <img src={data.filter(d => d.tipo === 'movil' && d.id === item).length > 0 ? data.filter(d => d.tipo === 'movil' && d.id === item)[0].ruta : ""} style={{ height: "100px", width: "auto" }} alt=''/>
                          }
                        </td>
                        <td className='text-center'>
                          <button type='button' onClick={() => eliminarImagen(item)} className="btn btn-outline-danger" style={{ color: "red", border: "none", top: "0", right: "0", padding: "0" }}>
                            X
                          </button>
                        </td>
                      </tr>
                    })}


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpenModal} closeModal={closeModal}>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="fecha" className="form-label">Imagen Escritorio <br /> (1920x757)px</label>
            <label style={{ color: 'red' }}>{mensajeE}</label>
            <input type="file" className="form-control" onChange={onFileChangeE} />
          </div>
          <div className="col-md-4">
            <label htmlFor="fecha" className="form-label">Imagen Tableta <br />(1080x1080)px</label>
            <label style={{ color: 'red' }}>{mensajeT}</label>
            <input type="file" className="form-control" onChange={onFileChangeT} />
          </div>
          <div className="col-md-4">
            <label htmlFor="fecha" className="form-label">Imagen Movil <br />(1080x1710)px</label>
            <label style={{ color: 'red' }}>{mensajeM}</label>
            <input type="file" className="form-control" onChange={onFileChangeM} />
          </div>
          <div className="col-md-12 text-right">
            <br />
            <button className="btn btn-outline-success" type="button" onClick={GuardarFile}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
              </svg>
              &nbsp;
              Guardar
            </button>
            <div >
              <br />
            </div>
          </div>

        </div>
      </Modal>

    </>
  );


}

List.propTypes = {
  saludo: PropTypes.string
}

List.defaultProps = {
  subtitulo: "Soy subtitulo"
}

export { List };