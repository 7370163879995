import React, {useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';

const Puntos = ({ history, match/*, saludo, subtitulo*/}) => {
  const path = match.url;

  const [configs, setConfigs] = useState({});
  //const [checked, setChecked] = useState(false);
  //const [vigencia, setVigencia] = useState(false);
  const [fechaVigencia, setfechaVigencia] = useState(new Date().toISOString().substr(0,10));
  const [conceptos, setconceptos] = useState([]);

  useEffect(() => {
        // console.log("Obteniendo data...");
        fetchConToken("configuraciones/"+getDataSession('empresa')).then(item => {
          // console.log(item);
          let cfg = null;

            if(item.es_Config_Sucursal === "N"){
              cfg = item.configuracionVM[0];
            }else{
              //Si no encuentra la sucursal regresa la configuración de la matríz
              cfg = item.configuracionVM
                .find(element => element.sucursal_Id === getDataSession('sucursal') 
                  ? element 
                  : item.configuracionVM[0]);
            }

            setConfigs(cfg);

            //setChecked(cfg.programa_Habilitado === 'S' ? true : false);
            //setVigencia(cfg.programa_Tiene_Vigencia === 'S' ? true : false);
            const d1 = new Date(cfg.fecha_Vigencia);
            setfechaVigencia(d1.toISOString().substr(0,10));

            getConceptos();
            
        }).catch(error=>{
          // console.log(error);
        }
        );
}, []);


const getConceptos = () => {
  fetchConToken("configuraciones/Conceptos/lista?empresaid="+getDataSession('empresa')+"&sucursalid="+getDataSession('sucursal'))
  .then( item => {
    // console.log(item);
    setconceptos(item);
  }).catch( error => {
    // console.log(error);
  });
}

const saveConfig = () =>{
  // console.log("entrando");
  fetchConToken("configuraciones",configs,"POST")
      .then(() => {
          alertService.success('La configuración se ha guardado correctamente.', { keepAfterRouteChange: true, autoClose: true });
          //history.push('.');
      })
      .catch(alertService.error);
}


const onChangeDate = (date) => {
  setConfigs({
    ...configs,
    fecha_Vigencia: date.target.value
  });
  setfechaVigencia(date.target.value);
}

const onChangeCheck = ({target}) => {
  const xCheck = target.checked ? 'S' : 'N';
  setConfigs({
    ...configs,
    [target.name]: xCheck
  });
}



    return (
        <>      

<div className="col-md-12 container-fluid" >
        <div className="container-fluid row">
          <div className="col-md-12">
        {/*<form onSubmit="" onReset="">*/}
          <div className="row col-md-12 mb-3">           
            <div className="col-sm-6 col-md-4">
                <div className="form-check form-switch">
                        <input type="checkbox" 
                            className="form-check-input" 
                            name="programa_Habilitado" 
                            role="switch" 
                            onChange={ onChangeCheck }
                            checked={ configs.programa_Habilitado === 'S' }
                            />
                        <label htmlFor="programa_Habilitado">{configs.programa_Habilitado === 'S' ? "Desactivar programa" : "Activar programa"}</label> &nbsp;&nbsp;
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
               <div className="form-check form-switch">
                        <input type="checkbox" 
                            className="form-check-input" 
                            name="programa_Tiene_Vigencia" 
                            role="switch" 
                            onChange={ onChangeCheck }
                            checked={ configs.programa_Tiene_Vigencia === 'S' }
                            />
                        <label htmlFor="programa_Tiene_Vigencia">Tiene Vigencia</label> &nbsp;&nbsp;
                </div>
              </div>

              <div className="col-sm-12 col-md-5 row"  style={{textAlign: "right"}}>
                <div className="col-sm-6 col-md-6" style={{ display: configs.programa_Tiene_Vigencia === 'S' ? 'block' : 'none' }}>
                  <input type="date" 
                  className="form-control" 
                  name="fecha_Vigencia" 
                  value={ fechaVigencia }
                  onChange={ onChangeDate } />
                </div>
                <div className="col-sm-6 col-md-6" style={{textAlign: "right"}}>
                <button className="btn btn-outline-success"  onClick={ saveConfig }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                    </svg>   
                    &nbsp;
                    Guardar
                </button>
                </div>
              </div>
            </div>

            
     
          {/*</form>*/}
          </div>
            <div className="col-md-4 container-fluid" >
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title"><b>Obtener puntos</b></h5>
                  <p className="card-text">Registra formas para que tus clientes ganen puntos ya sea por cada compra, al registrarse o en su cumpleaños</p>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <h5 className="card-title"><b>Redimir puntos</b></h5>
                  <p className="card-text">Registra formas para que tus clientes canjeen sus puntos ya sea por cada compra, descuentos o cupones</p>
                </div>
              </div>

            </div>

            <div className="col-md-8 container-fluid">
              <div className="card mb-3" >
                <div className="card-body">
                  <table className="table">
                          <thead>
                            <tr>
                              <th>Formas de ganar</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          
                      {conceptos.map(item => {
                        if (item.sumar === 'S') {
                          return <tr key={item.id}>
                            <td className="fs-6 fw-bold">{item.nombre}<br />
                              {
                                item.subConceptos.map(itm => {
                                  if (itm.estatus === "A") {
                                    if (item.clave === 'G001' && itm.subconcepto_Id === 1) {
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">{itm.puntos} Puntos cada que gaste $ {itm.moneda} </label></Fragment>
                                    } else if (item.clave === 'G001' /*&& itm.subconcepto_Id === 2*/) {
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">{itm.porcentaje} % de su compra genera puntos</label></Fragment>
                                    } else if (item.clave === 'RF01') {
                                      if (itm.clave_SubConcepto === 'SRF01')
                                        return <Fragment key={item.clave}><label className="fs-6 fw-light">{itm.puntos} puntos al referir </label></Fragment>
                                      if (itm.clave_SubConcepto === 'SRF02')
                                        return <Fragment key={item.clave}><label className="fs-6 fw-light">&nbsp; {itm.puntos} al referido</label></Fragment>                                     
                                    }
                                    else {
                                      return <Fragment key={1}><label className="fs-6 fw-light">{itm.puntos} Puntos</label></Fragment>
                                    }
                                  }
                                  return []
                                }
                                )
                              }
                            </td>
                            <td>
                              {
                                item.estatus === 'A' ?
                                  <Link to={`${path}/edit/${item.clave}`} className="btn btn-sm btn-link mr-1">Modificar</Link>
                                  :
                                  <Link to={`${path}/add/${item.clave}`} className="btn btn-sm btn-link mr-1">Activar</Link>
                              }
                            </td>
                          </tr>
                        } else {
                          return []
                        }

                      }
                      )
                      }
                            
                          </tbody>
                  </table>
                </div>
                <div className="card-body">
                    <table className="table">
                          <thead>
                            <tr>
                              <th>Formas de redimir</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          {conceptos.map(item => {
                            if(item.sumar === 'N'){
                              return <tr key={item.id}>
                                <td className="fs-6 fw-bold">{item.nombre}<br/>
                                {
                                item.subConceptos.map( itm => {
                                  if(itm.estatus === "A"){
                                    if(item.clave === 'R001' && itm.subconcepto_Id === 6){
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">$ { itm.moneda} por cada {itm.puntos} Puntos </label></Fragment>
                                    }else if(item.clave === 'R001' && itm.subconcepto_Id === 7){
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">{itm.porcentaje} % de descuento de su compra</label></Fragment>
                                    }else if(item.clave === 'R001' && itm.subconcepto_Id === 8){
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">$ { itm.moneda} por {itm.puntos} Puntos </label></Fragment>
                                    }else{
                                      return <Fragment key={item.clave}><label className="fs-6 fw-light">{itm.puntos} Puntos</label></Fragment>
                                    }
                                  }
                                  return []
                                }
                                )
                                }
                                </td>
                                <td>
                                <Link to={`${path}/edit/${item.clave}`} className="btn btn-sm btn-link mr-1">
                                  {item.estatus==="A" ? 'Modificar' : 'Activar'}
                                  </Link>
                                </td>
                                </tr>
                            }else{
                              return []
                            }

                          }
                          )
                          }
                            
                          </tbody>
                        </table>
                 </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
/*
Puntos.propTypes = {
    saludo: PropTypes.string.isRequired
}

Puntos.defaultProps = {
    subtitulo: "Soy subtitulo"
}
*/
export { Puntos };