import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setDataSession,getDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken, fetchConToken } from 'helpers/fetch';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';

// import { IconGlobe, IconYoutube, IconFacebook, IconInstagram } from 'assets/icons';


// import fondocolmd4 from 'images/login/Image_02.jpg';
//import logoFyttsa from 'images/login/FYTTSA_LOGO.png';
import logoFyttsa from 'images/login/logo_gsj.png';


// import fondo from 'images/login/Img_web_04.jpg';

import fondomovil from 'images/login/banner_movil_login.jpg';

import fondoImg from 'images/login/Img_web_07.jpg';
import fondocuadrado from 'images/login/Img_web_04.jpg';

//import fondoImg from 'images/login/banner_login.jpg';
//import fondocuadrado from 'images/login/cuadrado_login_2.jpg';

import logo from 'images/home/Logo_sj.png';

//import logo from 'images/home/Logo.png';

const Login = ({ history, saludo, subtitulo}) => {

  var today = new Date();
  var year = today.getFullYear();

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');

    const [error, setError] = useState(null);

    const [empresa, setEmpresa] = useState(0);
    const [sucursal, setSucursal] = useState(0);
    const [listEmpresas, setListEmpresas] = useState([]);
    const [listSucursales, setListSucursales] = useState([]);
    

    const [opcionModal, setOpcionModal] = useState('opcionLogin');
    

    const handleChangeEmpresa = (event) => {
      setDataSession('empresa',event.target.value);
    }
    
    const handleChangeSucursal = (event) => {
      setSucursal(event.target.value);
      var index = event.nativeEvent.target.selectedIndex;
      setDataSession('sucursalNombre',event.nativeEvent.target[index].text);
    }
    
    const handleLogin = () => {
      setError(null);
      setLoading(true);

      fetchSinToken("Usuarios/Login",{ usuario: username.value, clave: password.value },"POST")
        .then(response => {
          if(response.codigo === 500 || response.codigo === 401){
            setError(response.mensajes[0]);
            setLoading(false);
            return;
          }

          if(response.roles != null)
          {            
            const role =  response.roles.filter( rol => {return rol.id ===  3})
              if(role.length > 0 && role[0].id === 3)
             {
                setLoading(false);
                setError("Este usuario no tiene permisos para acceder.");
                return;
              }
          }
          else
          {
            setLoading(false);
            setError("Error al encontrar el rol.");
            return;
          }


          setUserSession(response.token, response.userName);
          fetchConToken("Empresas/Lista?pagina=1&totalxPagina=20&empresaId=0")
          .then(responseE => {

              setListEmpresas(responseE);
              setOpcionModal('opcionEmpresa');
              setLoading(false);

              if(responseE.length === 1){
                setEmpresa(responseE[0].id)
                setDataSession('empresa',responseE[0].id)
                ElegirEmpresa()
              }

            }).catch(error => {
              setLoading(false);
              if (error.response.status === 401) 
                  setError(error.response.data.message);
              else 
                  setError("Error al buscar las empresas. Por favor, intente más tarde.");
          });
      }).catch(error => {
          setLoading(false);
          if (error.response.status === 401) 
            setError(error.response.data.message);
          else 
            setError("Error al iniciar sesión. Por favor, intente más tarde.");
      });
    }

    const ElegirEmpresa =  () => {
      if(getDataSession('empresa')!= null && getDataSession('empresa') === 0)
      {
        setError("Seleccione una empresa.");
        return;
      }
      setLoading(true);
      //setDataSession('empresa',empresa);

      fetchConToken(`sucursales/Lista?pagina=1&totalxPagina=10&empresaId=${getDataSession('empresa')}`)
      .then(response => {
          setListSucursales(response);
          setOpcionModal('opcionSucursal');
          if(response.length > 0){
             setDataSession('sucursal',response[0].id);
             setDataSession('sucursalNombre',response[0].nombre);
             history.push('/dashboard');
          }
          setLoading(false);
      }).catch(error => {

        setLoading(false);
        if (error.response.status === 401) setError(error.response.data.message);
        else setError("Error al buscar las sucursales. Por favor, intente más tarde.");
      });

    }

    const ElegirSucursal = async () => {
      if(sucursal!= null && sucursal === 0)
      {
        setError("Seleccione una sucursal.");
        return;
      }

      setLoading(true);
      setDataSession('sucursal',sucursal);
      history.push('/dashboard');
      setLoading(false);
    }

    const DivEmpresa =() => {
      return (
      <> 
      <div className="col-md-12 text-center">
             <Link to={SLUGS.home}>
              <img src={logoFyttsa} className="img-fyt" alt=''/>
              </Link>
              <h2 style={{color: "white"}}><b>Bienvenido</b></h2>
              <label style={{color: "white"}}>Selecciona una empresa</label>
           </div>
           <br/>
           <div className="col-md-12">
              <div className="col-md-10 container">
               <label style={{color: "white"}}><b>Empresas</b></label>
                 <div className="input-group">
                        <select className="form-control" value={empresa} onChange={handleChangeEmpresa}  >
                         <option key={0} value="0">Elegir...</option>
                        {listEmpresas.map(item => {
                           return  <option key={item.id} value={item.id}>{item.razon_Social}</option>
                        })}
                        </select>
                  </div>
             </div>
           </div>
           <br/>                                              
           <div className="card-footer text-center">
             {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
             <input style={{borderRadius: "30px"}} type="button" className="btn-carrousel-home-index" value={loading ? 'Cargando...' : 'Seleccionar'} onClick={ElegirEmpresa} disabled={loading} /><br />
             <a href="forgotpassword" className="text-center" style={{color: "white"}}> ¿Olvidaste tu contraseña?</a>                                
           </div>
           </>
      )
     }

     const DivSucursal =() => {
      return (
      <> 
      <div className="col-md-12 text-center">
             <Link to={SLUGS.home}>
              <img src={logoFyttsa} className="img-fyt" alt=''/>
              </Link>
              <h2 style={{color: "white"}}><b>Bienvenido</b></h2>
              <label style={{color: "white"}}>Selecciona una sucursal</label>
           </div>
           <br/>
           <div className="col-md-12">
              <div className="col-md-10 container">
               <label style={{color: "white"}}><b>Sucursales</b></label>
                 <div className="input-group">
                        <select className="form-control" value={sucursal} onChange={handleChangeSucursal}>
                        <option key={0} value="0">Elegir...</option>
                        {listSucursales.map(item => {
                           return  <option key={item.id} value={item.id}>{item.nombre}</option>
                        })}
                        </select>
                  </div>
             </div>
           </div>
           <br/>                                              
           <div className="card-footer text-center">
             {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
             <input style={{borderRadius: "30px"}} type="button" className="btn-carrousel-home-index" value={loading ? 'Cargando...' : 'Seleccionar'} onClick={ElegirSucursal} disabled={loading} /><br />
             <a href="forgotpassword" className="text-center" style={{color: "white"}}> ¿Olvidaste tu contraseña?</a>                                
           </div>
           </>
      )
     }
    

    return (
      <>
      <div className="col-12 col-sm-12 col-md-12 row login" style={{minHeight: "100vh",marginLeft: "0"}}>
        <div className="col-12 col-md-8" style={{backgroundImage: `url("${(Math.abs(window.innerWidth - window.innerHeight) < 200)  ?  fondocuadrado  : fondoImg }")`,backgroundRepeat: "no-repeat", display: window.innerWidth < 768 ? 'none' : 'block',backgroundSize: "cover",backgroundPosition: "center"}}>
          {/*<div className="div-emonedero" >
              <img src={ logo}  />
           </div>
           <div className="text-center" style={{height: "70%"}}>
           <div className="col-md-12" style={{  position: "absolute",top: "20%",width: "60%"}}>
             <h1 style={{color: "#189ad6",fontSize: "3.5vw"}}><b>LA FUERZA DE</b></h1>
             <h3 style={{color: "#ffff",fontSize: "2.5vw"}}>UNA GRAN FAMILIA</h3>
          </div>
              <div style={{ width: "100%",height: "100%"}}>
              
                <div className="col-md-12" style={{  position: "absolute",bottom: "40%",width: "60%"}}>
                  <h3 style={{color: "#ffff"}}><b>Bienvenido a Fyttsa Club</b></h3>
                  <p style={{color: "#009CDE"}}>Control y gestion de puntos y recompensas</p>
                </div>
              </div>
              

              
           </div>
           */}
        </div>
        <div className="col-12 col-md-4 zz" style={{ backgroundRepeat: "no-repeat",backgroundPosition: "center",backgroundAttachment: "fixed",backgroundImage: window.innerWidth < 768 ? `url(${fondomovil})` : 'none', backgroundSize: "cover"}}>
        <form style={{ marginBottom: "100px"}}>
            { opcionModal === "opcionLogin" ? (
              <div style={{marginTop: (window.innerWidth < window.innerHeight)  ? "40px" : (window.innerWidth > 768 ? "40px" : "0vw" )}}>
              <div className="col-md-12 text-center">
              <Link to={SLUGS.home}>
              <img src={ window.innerWidth < 768 ? logo : logoFyttsa } className="img-fyt" alt='' />
              </Link>
              <h2 style={{color: window.innerWidth < 768 ? "#ffff" : "#33506f"}}><b>Bienvenido</b></h2>
              <p style={{color: window.innerWidth < 768 ? "#ffff" : "#189ad6"}}>Inicie sesión para continuar</p>
           </div>
           <br/>
           <div className="col-md-12">
              <div className="col-md-10 container">
                 <div className="input-group">
                                   <span className="fa fa-user"></span>
                                     <input 
                                     type="text" {...username} 
                                     className="form-control" 
                                     autoComplete="new-password" 
                                     placeholder="Correo" />
                  </div>
             </div>
             <br/>
             <div className="col-md-10 container">
                 <div className="input-group">
                   <span className="fa fa-lock"></span>
                   <input 
                                     type="password" {...password} 
                                     className="form-control" 
                                     autoComplete="new-password" 
                                     placeholder="Clave" />
                 </div>
             </div>
           </div>
           <br/>                                              
           <div className="col-md-10 container">
             {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
             <input style={{ width:"100%"}} type="button" className="btn-carrousel-home-index" value={loading ? 'Cargando...' : 'Ingresar'} onClick={handleLogin} disabled={loading} /><br />
             <b><a href="forgotpassword" className="text-center" style={{ float: "right",color: window.innerWidth < 768 ? "#ffff" : "#16375b"}}> ¿Olvidaste tu contraseña?</a></b>
             
           </div>
           </div>
            ) : ("") }
            { opcionModal === "opcionEmpresa" ? (
              <DivEmpresa/>
            ) : ("") }
            { opcionModal === "opcionSucursal" ? (
              <DivSucursal/>
            ) : ("") }

       
         </form>
         <div className='text-center derechos-reservados' style={{zIndex: "10", position: "absolute",bottom: "0",width: window.innerWidth < 768 ? "95%" : "32%"}}>
            <p className={window.innerWidth < 768 ? 'white' : 'black'} style={{color: "transparent"}}>LEALME</p>
            <p className={window.innerWidth < 768 ? 'white' : 'black'} >{`© ${year} LEALME`}</p>
            <p className={window.innerWidth < 768 ? 'white' : 'black'}>Algunos derechos reservados | Aviso de privacidad</p>
          </div>
        </div>
      </div>
      </>
    );
}



const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}


Login.propTypes = {
    saludo: PropTypes.string
}

Login.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { Login };