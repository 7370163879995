import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { removeUserSession } from 'views/Utils/Common';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;

    async function logout() {
        removeUserSession();
        push('..');//SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.dashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.dashboard)}
            />
            <MenuItem
                id={SLUGS.puntos}
                items={[SLUGS.overviewTwo, SLUGS.overviewThree]}
                title='Programa'
                icon={IconOverview}
            >
                <MenuItem
                    id={SLUGS.puntos}
                    title='Puntos'
                    level={2}
                    icon={IconAgents }
                    onClick={() => onClick(SLUGS.puntos)}
                />
               
                <MenuItem
                    id={SLUGS.notificacionp}
                    title='Notificaciones'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.notificacionp)}
                />
                <MenuItem
                    id={SLUGS.actividadp}
                    title='Movimientos'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.actividadp)}
                />
                <MenuItem
                    id={SLUGS.ventasp}
                    title='Ventas'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.ventasp)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.clientes}
                title='Clientes'
                icon={IconTickets}
                onClick={() => onClick(SLUGS.clientes)}
            />

            
            <MenuItem
                id={SLUGS.promociones}
                title='Promociones'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.promociones)}
            />
            <MenuItem
                id={SLUGS.recompensas}
                title='Canjes'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.recompensas)}
            />
            
            {/* <MenuItem
                id={SLUGS.campanias}
                title='Campañas'
                icon={IconArticles}
                onClick={() => onClick(SLUGS.campanias)}
            /> */}

            <MenuItem
                id={SLUGS.sucursal}
                items={[SLUGS.ideasTwo, SLUGS.ideasThree]}
                title='Configuraciones'
                icon={IconIdeas}
            >
                 <MenuItem
                    id={SLUGS.slider}
                    title='Slider'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.slider)}
                />
                 <MenuItem
                    id={SLUGS.sliderCliente}
                    title='sliderCliente'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.sliderCliente)}
                />
                <MenuItem
                    id={SLUGS.sucursal}
                    title='Sucursales'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.sucursal)}
                />
                <MenuItem
                    id={SLUGS.users}
                    title='Usuarios'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.users)}
                />
               
               <MenuItem
                    id={SLUGS.articulos}
                    title='Artículos'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.articulos)}
                />
                
               <MenuItem
                    id={SLUGS.categorias}
                    title='Lineas'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.categorias)}
                />

                <MenuItem
                    id={SLUGS.departamentos}
                    title='Grupos'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.departamentos)}
                />
                
                <MenuItem
                    id={SLUGS.etiquetas}
                    title='Etiquetas'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.etiquetas)}
                />
                
                <MenuItem
                    id={SLUGS.roles}
                    title='Roles'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.roles)}
                />
            </MenuItem>

            
            <div className={classes.separator}></div>
            <MenuItem
                id={SLUGS.settings}
                title='Settings'
                icon={IconSettings}
                onClick={() => onClick(SLUGS.settings)}
            />

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}

export default SidebarComponent;

//<MenuItem
//                id=SLUGS.subscription}
//                title='Newsletter'
//                icon={IconSubscription}
//                onClick={() => onClick(SLUGS.subscription)}
//            />