// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('token') || null;
  }
  
  // return data key from the session storage
  export const getDataSession = (keyData) => {
    return sessionStorage.getItem(keyData) || null;
  }

  // set the token and user from the session storage
  export const setDataSession = (keyData, valueData) => {
    sessionStorage.setItem(keyData, valueData);
  }

  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('empresa');
    sessionStorage.removeItem('sucursal');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  export const getFirstDay = () =>{
    return new Date(new Date().getFullYear(),new Date().getMonth(), 1).toISOString().substr(0,10);
  }

  export const getLastDay = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth()+1,new Date().getFullYear())).toISOString().substr(0,10);
  }