import React from 'react'

//<div className="referidos">
 //       <div className="background" />

const Refered = ({ match, saludo, subtitulo}) => {
    return (
      
<div className="referidos fs-6">
    <div className="background" />
        <div className="rectangle-1-8" />
        <div className="rectangle-1-9" />
        <p className="text-1">Modificar</p>
        <p className="text-2">Referidos</p>
        <p className="text-3">Configuraciónes</p>
        <p className="text-4">Recompensas por referencias</p>
        <p className="text-5">Recompensas a cliente por recomendación</p>
        <p className="text-6">$5.00 
  </p>
        <p className="text-7">MXN</p>
        <p className="text-8">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
  
  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
        <p className="text-9">Cupón 
  de descuento </p>
        <p className="text-1-0">Canjes</p>
        <p className="text-1-1">25</p>
        <div className="search">
          <div className="rectangle-1-6" />
          <div className="rectangle-1-7" />
          <p className="text-1-2">Off</p>
          <p className="text-1-3">Habilitar programa</p>
        </div>
        <div className="search">
          <div className="rectangle-1-6" />
          <div className="rectangle-1-7" />
          <p className="text-1-4">Off</p>
          <p className="text-1-5">On</p>
        </div>
        <img src="" alt='' />
        <div className="rectangle-2-1" />
        <p className="text-1-6">Modificar</p>
        <p className="text-1-7">Recompensas al amigo referido</p>
        <p className="text-1-8">$45.00 
  </p>
        <p className="text-1-9">MXN</p>
        <p className="text-2-0">Cupón 
  de descuento </p>
        <p className="text-2-1">Canjes</p>
        <p className="text-2-2">25</p>
        <div className="search">
          <div className="rectangle-1-6" />
          <div className="rectangle-1-7" />
          <p className="text-2-3">Off</p>
          <p className="text-2-4">On</p>
        </div>
        <img src="" alt='' />
        <div className="h-e-a-d-e-r">
          <div className="rectangle-2" />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <p className="text-2-5">Buscar...</p>
          <img src="" alt='' />
          <div className="mask-group">
            <div className="ellipse-1" />
            <img src="" alt='' />
          </div>
          <p className="text-2-7">Administrador</p>
          <img src="" alt='' />
          <img src="" alt='' />
        </div>
        <div className="menu">
          <div className="rectangle-1" />
          <p className="text-2-8">Dashboard
  Programas
  Clientes
  Estadísticas
  Promociónes
  Recompensas
  Campañas
  Configuraciónes
  Settings
  Logout</p>
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          <img src="" alt='' />
          
        </div>
        <img src="" alt='' />
        <img src="" alt='' />
      </div>
    )
  }
  
  
  export { Refered };