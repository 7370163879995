import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from './AddEdit';
import { Referidos } from './Referidos';
import { Refered } from './Refered';


function Referred({ match }) {
    const { path } = match;

    //console.log(`path- ${match.url}/add`);

    return (
        <Switch>
            <Route exact path={path} component={Referidos} />
            <Route path={`${path}/add`} component={Refered} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
            <Redirect from="*" to={{
                                pathname: Referidos
                            }}/>
        </Switch>
    );
}


export { Referred };

//export default Sucurs;