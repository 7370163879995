import React from 'react';
//import { any } from 'prop-types';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Referidos = ({ match, saludo, subtitulo}) => {
  const path = match.url;

    return (
        <>
         <div className="row container-fluid" >
        
            <div className="col-md-4">
              <div className="card mb-3" style={{width: "18rem"}}>
                  <div className="card-body mb-3">
                     <div className="card-title">
                        <label className="fs-6 fw-bold">Recompensas por referencia</label>
                     </div>
                    <p className="card-text">Recompense a sus clientes actuales por recomendar a sus amigos y animarlos a probar su marca.</p>
                    <p className="card-text">El cliente existente obtiene su recompensa una vez que el amigo realiza su primer pedido. Obtenga más información sobre las referencias desde la perspectiva del cliente.</p>
                   
                  </div>
                  <div className="form-check form-switch mb-3 text-center" style={{paddingLeft: "0.5rem"}}>
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{marginLeft: "0px",fontSize: "25px"}}/>
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{fontSize: "20px"}}>Habilitar programa</label>
                    </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-3" style={{width: "18rem"}}>
                  <div className="card-body mb-3 text-center">
                     <div className="card-title">
                        <label className="fs-6 fw-bold">Recompensas a cliente por recomendación</label>
                     </div>
                     <div className="mb-3">
                       <p>Cupón de descuento </p>
                       <h1><b>$5.00</b></h1>
                       <h1><b>MXN</b></h1>

                       <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                        </svg>
                          &nbsp;
                          <b>25</b> 
                          &nbsp;
                          Canjes
                       </p>

                        <div className="col-md-12 text-center">

                        <div className="form-check form-switch">
                                <input type="checkbox" 
                                    id="chkganaclienteref"
                                    className="form-check-input" 
                                    name="subconcepto_Id" 
                                    role="switch" 
                                                        />
                                <label htmlFor="subconcepto_Id">Puntos fijos</label> &nbsp;&nbsp;
                            </div>

                       {/* <Form.Switch
                                    id="chkganaclienteref"
                                    role="switch" 
                                    name="subconcepto_Id" 
                                    label= "Puntos fijos"
                       />*/}
                        </div>
                        <Link to={`${ path}/edit/1`} className="">Modificar</Link>
                     </div>
                  </div>
              </div>
            </div>

            <div className="row col-md-8">

              <div className="card">
                <div className="card-header">
                  <label>Recompensa al cliente por recomendación</label>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                      <th><label></label></th>
                      <th></th>
                      <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Cupón de descuento de $5.00 <br/> <pre> 0 canjes</pre> </td>
                        <td>
                       
                        </td>
                       
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="card-footer">

                </div>

              </div>

              <div className="card">
                <div className="card-header">
                  <label>Recompensa al amigo referido</label>
                </div>
                <div className="card-body">

                <table className="table">
                    <thead>
                      <tr>
                      <th><label></label></th>
                      <th></th>
                      <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Cupón de descuento de $5.00 <br/> <pre> 0 canjes</pre> </td>
                        <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" id="chkganareferido" />
                        </div>
                        </td>
                        <td><Link to={`${ path}/edit/2`} className="">Modificar</Link></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="card-footer">

                </div>

              </div>

            
            </div>
        </div>
       

        

        </>
    );
}

Referidos.propTypes = {
    saludo: PropTypes.string.isRequired
}

Referidos.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { Referidos };