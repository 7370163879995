import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken,fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import  Pagination  from 'components/pagination/Pagination'

import * as FileSaver from "file-saver";
const List = ({ match, saludo, subtitulo}) => {

  const { path } = match;

 
    let [data, setData] = useState([])
    // eslint-disable-next-line
    const [totalxPagina,setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState("")

    async function getData(page){
      const response = await fetchConToken(`sucursales/lista?empresaid=${getDataSession('empresa')}&pagina=${page}&totalxpagina=${totalxPagina}&busqueda=${busqueda}`);
      setData(response);
    }
 
    const exportToCSV = () => {
      fetchConTokenFile(`Exportar/Excel?empresaid=${getDataSession('empresa')}&pagina=1&totalxpagina=${totalxPagina}&busqueda=${busqueda}&tipo=SUCURSALES&columnas=id,nombre,direccion,telefono`,null,'GET',true)
      .then(function(response) {
        return response.blob();
      }).then(function(blob) {
        FileSaver.saveAs(blob, 'sucursales.csv');
      })
    }

    React.useEffect(()=>{
      getData(1);
      // eslint-disable-next-line
    }, []);

    const handleChangeBusqueda = ({target}) => {
      setbusqueda(target.value);
    }
    return (
        <>
        <div className="container-fluid" >
        <div className="row row mb-3" >
              <div className="col-12 col-sm-12 col-md-12" style={{textAlign: "right"}}>
                      <Link to={`${path}/add`} className="btn btn-outline-success"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        &nbsp;
                        Nuevo 
                      </Link>
                      &nbsp;
                      <button className="btn btn-write" onClick={(e) => exportToCSV()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16" style={{color: "#016e38"}}>
                          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z"/>
                        </svg>
                        &nbsp;
                        Descargar 
                      </button>
                      &nbsp;
              </div>
          </div>
              <div className="col-12 col-sm-12 col-md-12 ">
                <div className="col-6 col-sm-4 col-md-4 mb-3" style={{float: "right"}}>
                  <div className="input-group">
                     <input type="text" className="form-control" placeholder="buscar..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={handleChangeBusqueda}/>
                     <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>getData(1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                      </svg>
                     </button>
                  </div>
                </div>
                </div>
          

          <div className="card col-12 col-sm-12 col-md-12">
              <div style={{overflowX: "auto",maxWidth: "90vw"}}>
              <table className="table">
                  <thead> 
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Direccion</th>
                      <th>Telefono</th>
                      <th>Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                          {data.map(item => {

                          return  <tr key={item.id}>

                            <td>{item.id}</td>
                            <td>{item.nombre}</td>
                            <td>{item.direccion}</td>
                            <td>{item.telefono}</td>
                            <td>
                              <Link to={`${path}/edit/${item.id}`} className="btn  mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </Link>
                            </td>
                            

                            </tr>

                          })}
                  </tbody>
                </table>
                <Pagination consumirPaginacion={getData} elementos={data.length} totalxPagina={totalxPagina}/>
              </div>
            </div>
            </div>
        </>
    );    


}

List.propTypes = {
    saludo: PropTypes.string
}

List.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { List };