import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Moment from 'moment';
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';

import { Swiper, SwiperSlide } from 'swiper/react';
/** Swiper***/
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
//import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import PaginationPages from 'components/pagination/Pagination'

import SwiperCore, {
    EffectCoverflow, Pagination, Navigation
} from 'swiper/core';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);




const AddEdit = ({ history, match }) => {

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [isModalImagen, setIsModalImagen] = useModal(false);

    let [articulosSearch, setArticulosSearch] = useState([])
  // eslint-disable-next-line
    let [totalxPagina, setTotalxPagina] = useState(10)
    const busqueda = useFormInput('');
    const [tiposPromocion, setTiposPromocion] = useState([]);
    const [promocionesDet, setPromocionesDet] = useState([]);
    const [promocionesDetAux, setPromocionesDetAux] = useState([]);
  // eslint-disable-next-line
    const [fileImg, setfileImg] = useState(null);

    const { id } = match.params;
    const isAddMode = !id;

    const [promocionesImagenes, setPromocionesImagenes] = useState([]);
    const [promocionesDetImagenes, setPromocionesDetImagenes] = useState([]);


    const { handleSubmit, reset } = useForm();
    const [promociones, setPromociones] = useState({
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        estatus: 'A',
        detalles: promocionesDet
    });

    async function buscarTiposPromocion(data) {
        const response = await fetchConToken(`tiposPromociones/Lista?pagina=1&totalxPagina=20&empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}`)
        setTiposPromocion(response);

    }

    async function buscarArticulos(page) {
        const response = await fetchConToken(`catalogos/Articulos/Lista?pagina=${page}&totalxPagina=${totalxPagina}&empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&busqueda=${busqueda.value}`)
        setArticulosSearch(response);

    }

    const handleChange = (e) => {
        const { id, name, value } = e.target;
        setPromocionesDet(promocionesDet.map(promo => (
            //Si se agrega un tercer '=' deja de funcionar
            // eslint-disable-next-line
            promo.articulo_Id == id ? { ...promo, [name]: value } : promo
        )))
        // console.log(promocionesDet)

    };

    // console.log(errors);

    useEffect(() => {
        reset(promociones);
  // eslint-disable-next-line
    }, [promociones]);

    function onSubmit(data) {
        data.detalles = promocionesDet
        // console.log(data)
        return isAddMode
            ? createSuc(data)
            : updateSuc(id, data);
    }

    function createSuc(data) {
        return fetchConToken("promociones", data, "POST")
            .then(() => {
                alertService.success('Promocion agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateSuc(id, data) {
        return fetchConToken("promociones", data, "PUT")
            .then(() => {
                onFileUpload('P', promociones.id);

                alertService.success('promocion modificado correctamente', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const onFileUpload = (tipo, id) => {
        if (fileImg != null && fileImg.name != null) {
            const formData = new FormData();

            formData.append("id", id);
            formData.append("tipo", 'P');
            formData.append("Empresa_Id", getDataSession('empresa'));
            formData.append("Sucursal_Id", getDataSession('sucursal'));
            formData.append("Nombre", fileImg.name);

            formData.append(
                "FileToUpload",
                fileImg,
                fileImg.name
            );
            //console.log(fileImg);
            fetchConToken("promociones/Imagenes", formData, "POST", true)
                .then(() => {
                    alertService.success('Imagen cargada.', { keepAfterRouteChange: true });
                    //history.push('..');
                })
                .catch(alertService.error);
        }

    };

    async function getData(page) {
        // console.log("Obteniendo data...");
        await fetchConToken(`promociones?id=${id}&pagina=${page}&totalxPagina=${totalxPagina}`).then(promocion => {
            promocion.fecha_Inicio = Moment(promocion.fecha_Inicio).format('YYYY-MM-DD')
            promocion.fecha_Fin = Moment(promocion.fecha_Fin).format('YYYY-MM-DD')
            setPromociones(promocion);
            setPromocionesDet(promocion.detalles);
            setPromocionesDetAux(promocion.detalles)
            setPromocionesImagenes(promocion.imagenes)
        });
    }

    useEffect(() => {
        if (!isAddMode) {
            getData(1)
        }
        buscarTiposPromocion()
  // eslint-disable-next-line
    }, []);


    const onChangeCheck = ({ target }) => {
        const xCheck = target.checked ? 'A' : 'I';
        setPromociones({
            ...promociones,
            [target.name]: xCheck
        });
    }

    function elejirArticulo(articulo_id, categoria_id, articulo) {
        var det = {
            articulo_Id: articulo_id,
            categoria_id: categoria_id,
            articulo: articulo,
            precio_normal: 0,
            precio_rebajado: 0
        }
        var detAux = {
            id: articulo_id,
            articulo_Id: articulo_id,
            categoria_id: categoria_id,
            articulo: articulo,
            precio_normal: 0,
            precio_rebajado: 0
        }
        promocionesDet.push(det)
        promocionesDetAux.push(detAux)
    }

    const onFileChange = ({ target }) => {
  // eslint-disable-next-line
        const { id, name, value } = target;
        if (target.files[0] != null && target.files[0].name != null) {
            const formData = new FormData();

            formData.append("id", id);
            formData.append("tipo", 'P');
            formData.append("Empresa_Id", getDataSession('empresa'));
            formData.append("Sucursal_Id", getDataSession('sucursal'));
            formData.append("Nombre", target.files[0].name);

            formData.append(
                "FileToUpload",
                target.files[0],
                target.files[0].name
            );
            fetchConToken("promociones/Imagenes", formData, "POST", true)
                .then((imagen) => {
                    alertService.success('Imagen cargada.', { keepAfterRouteChange: true });
                    setPromocionesImagenes([
                        ...promocionesImagenes,
                        imagen
                    ])
                })
        }
    }

    const onFileChangeD = ({ target }) => {
        console.log("entre");
  // eslint-disable-next-line
        const { id, name, value } = target;
        if (target.files[0] != null && target.files[0].name != null) {
            const formData = new FormData();

            formData.append("id", id);
            formData.append("tipo", 'D');
            formData.append("Empresa_Id", getDataSession('empresa'));
            formData.append("Sucursal_Id", getDataSession('sucursal'));
            formData.append("Nombre", target.files[0].name);

            formData.append(
                "FileToUpload",
                target.files[0],
                target.files[0].name
            );
            fetchConToken("promociones/Imagenes", formData, "POST", true)
                .then(imagen => {
                    alertService.success('Imagen cargada.', { keepAfterRouteChange: true });
                    const detImagenes = promocionesDet.filter(imagenes => imagenes.id === id)
                    detImagenes[0].imagenes.push(imagen)
                    setPromocionesDet(promocionesDet.map(d => (d.id === id ? detImagenes : d)));
                })
                .catch(alertService.error);
        }
    }


    const eliminarImagen = (cabeceraId, imagenId, tipo) => {
        fetchConToken(`Promociones/Imagenes?id=${cabeceraId}&imagen_Id=${imagenId}&tipo=${tipo}`, null, "DELETE")
            .then(imagen => {
                alertService.success('Imagen eliminada.', { keepAfterRouteChange: true })
                if (tipo === 'P') {
                    const imagenes = promocionesImagenes.filter(imagenes => imagenes.id !== imagenId)
                    setPromocionesImagenes(imagenes)
                }
                if (tipo === 'D') {
                    const detImagenes = promocionesDetImagenes[0].imagenes.filter(imagenes => imagenes.id !== imagenId)
                    const detImg = promocionesDetImagenes;
                    detImg[0].imagenes = detImagenes;
                    setPromocionesDetImagenes(promocionesDetImagenes => ([...promocionesDetImagenes, ...detImg]));
                }
            })
            .catch(alertService.error);
    }

    const imagenesDet = (detalleId) => {
        // console.log(promocionesDet)
        const detImagenes = promocionesDet.filter(imagenes => imagenes.id === detalleId)
        // console.log(detImagenes)
        setPromocionesDetImagenes(detImagenes)
        setIsModalImagen(true)
        openModal()
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setPromociones({
            ...promociones,
            [name]: value
        });
    }

    const handleChangeArticulo = (event) => {
        setPromociones({
            ...promociones,
            tipo_Promocion_Id: event.target.value
        });
    }

    return (
        <>
            <div className="container-fluid" >
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className="mb-3" >
                        <div className="row" style={{ textAlign: "left" }}>
                            <div className="col-md-6" style={{ textAlign: "left" }}>
                                <h3>{isAddMode ? 'Agregar Promoción' : 'Editar Promoción'}</h3>
                            </div>
                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                <button className="btn btn-outline-success" type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                    </svg>
                                    &nbsp;
                                    Guardar
                                </button>
                                &nbsp;
                                <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z" />
                                    </svg>
                                    &nbsp;
                                    Regresar
                                    &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card mb-3 ">
                                <div className="card-body">
                                    <label htmlFor="tipo_promocion_id" className="form-label">Tipo promoción</label>
                                    <select className="form-control" value={promociones.tipo_Promocion_Id} onChange={handleChangeArticulo} required={true} >
                                        <option key={0} value="0">Elegir...</option>
                                        {tiposPromocion.map(item => {
                                            return <option key={item.id} value={item.id}>{item.nombre}</option>
                                        })}
                                    </select>
                                    <br />
                                    <label htmlFor="nombre" className="form-label">Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="nombre"
                                        name='nombre'
                                        required={true}
                                        maxLength={80}
                                        value={promociones.nombre || ""}
                                        onChange={handleInputChange}
                                    />
                                    <br />
                                    <label htmlFor="nombre" className="form-label">Descripcion</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="descripcion"
                                        name='descripcion'
                                        required={true}
                                        maxLength={80}
                                        value={promociones.descripcion || ""}
                                        onChange={handleInputChange}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card mb-3 ">
                                <div className="card-body">
                                    <label htmlFor="fecha_inicio" className="form-label">Fecha inicio</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="fecha inicio"
                                        name='fecha_Inicio'
                                        required={true}
                                        format='yyyy-MM-dd'
                                        value={promociones.fecha_Inicio || ""}
                                        onChange={handleInputChange}
                                    />
                                    <br />
                                    <label htmlFor="fecha_fin" className="form-label">Fecha fin</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="fecha fin"
                                        name='fecha_Fin'
                                        required={true}
                                        format='yyyy-MM-dd'
                                        value={promociones.fecha_Fin || ""}
                                        onChange={handleInputChange}
                                    />
                                    <br />

                                    <div className="form-check form-switch">
                                        <input type="checkbox"
                                            className="form-check-input"
                                            name="estatus"
                                            role="switch"
                                            onChange={onChangeCheck}
                                            checked={promociones.estatus === 'A'}
                                        />
                                        <label htmlFor="estatus">Activar promoción</label> &nbsp;&nbsp;
                                    </div>
                                    <div className="col-md-12">
                                        {isAddMode ? '' :
                                            <input type="file"
                                                className="form-control"
                                                name=""
                                                id={id}
                                                multiple={false}
                                                onChange={onFileChange}
                                            />
                                        }
                                        <div style={{ marginTop: '2rem' }}>
                                            {isAddMode ? '' :
                                                <div className='text-center'>
                                                    <p style={{ marginBottom: '1rem' }}>Imagenes {promocionesImagenes.length}</p>
                                                    <Swiper
                                                        spaceBetween={2}
                                                        navigation={true}
                                                        centeredSlides={false}
                                                        slidesPerView={3}
                                                        pagination={{
                                                            clickable: true
                                                        }}
                                                        className="Swiperprincipal2"
                                                    >
                                                        {
                                                            promocionesImagenes.map(items => {
                                                                return <SwiperSlide key={items.id} >
                                                                    <div style={{ display: 'inline-block', position: 'relative' }}>
                                                                        <button type='button' onClick={() => eliminarImagen(id, items.id, 'P')} className="modal-close btn btn-outline-danger" style={{ color: "red", border: "none", top: "0", right: "0", padding: "0", position: 'absolute' }}>
                                                                            X
                                                                        </button>
                                                                        <img src={items.ruta} alt='' />
                                                                    </div>
                                                                </SwiperSlide>
                                                            })
                                                        }
                                                    </Swiper>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card mb-3 ">
                                <div className="card-body">
                                    <div className="card-title">
                                        {isAddMode ?
                                            <button type="button" className="btn btn-outline-danger" onClick={openModal}>Agregar artículo</button> : ''
                                        }
                                    </div>
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Artículo</th>
                                                    <th>Porcentaje</th>
                                                    <th>Precio</th>
                                                    <th>Precio descuento</th>
                                                    <th>Img</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    promocionesDet.map(item => {
                                                        return <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.articulo}</td>
                                                            <td><input type="number" id={item.articulo_Id} className="form-control" value={item.porcentaje_Descuento} name="porcentaje_Descuento" onChange={handleChange} /></td>
                                                            <td><input type="number" id={item.articulo_Id} className="form-control" value={item.precio_normal} name="precio_normal" onChange={handleChange} /></td>
                                                            <td><input type="number" id={item.articulo_Id} className="form-control" value={item.precio_rebajado} name="precio_rebajado" onChange={handleChange} /></td>
                                                            <td>
                                                                {isAddMode ? '' :
                                                                    <input type="file" id={item.id} className="form-control" onChange={onFileChangeD} />
                                                                }
                                                            </td>
                                                            <td>
                                                                {isAddMode ? '' :
                                                                    <button type='button' onClick={() => imagenesDet(item.id)}>Imagenes</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <PaginationPages consumirPaginacion={getData} elementos={promocionesDet.length} totalxPagina={totalxPagina} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {!isModalImagen ?
                    <div>
                        <br />
                        <div className="row">
                            <div className="col-md-2 tex-left">
                                <label>Buscar </label>
                            </div>
                            <div className="col-md-8 ">
                                <input type="text" className="form-control" {...busqueda} />
                            </div>
                            <div className="col-md-2 text-left">
                                <button type="button" className="btn btn-outline-secondary" onClick={() => buscarArticulos(1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Clave</th>
                                    <th>Artículo</th>
                                    <th>Existencias</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    articulosSearch.map(item => {
                                        return <tr>
                                            <td>{item.id}</td>
                                            <td>{item.clave}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.existencias}</td>
                                            <td>
                                                <button type="button" className="btn btn-outline-info" onClick={() => elejirArticulo(item.id, item.categoria_id, item.nombre)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div>
                        <div className='text-center'>
                            <p style={{ paddingBottom: '2rem' }}>Imagenes {promocionesDetImagenes[0].imagenes.length}</p>
                            <Swiper
                                spaceBetween={2}
                                navigation={true}
                                centeredSlides={true}
                                slidesPerView={3}
                                pagination={{
                                    clickable: true
                                }}
                                className="Swiperprincipal2"
                            >
                                {
                                    promocionesDetImagenes[0].imagenes.map(items => {
                                        return <SwiperSlide key="slide1" >
                                            <div style={{ display: 'inline-block', position: 'relative' }}>
                                                <button type='button' onClick={() => eliminarImagen(promocionesDetImagenes[0].id, items.id, 'D')} className="modal-close btn btn-outline-danger" style={{ color: "red", border: "none", top: "0", right: "0", padding: "0", position: 'absolute' }}>
                                                    X
                                                </button>
                                                <img src={items.ruta} alt='' />
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }
const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);

    }
    return {
        value,
        onChange: handleChange
    }
}


export { AddEdit };