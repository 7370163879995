import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import { Sucurs } from 'views/sucursales/Index';
import { Clientes } from 'views/clientes/Index';
import { Programa } from 'views/puntos/Index';
import { Roles } from 'views/roles/Index';
import { Referred } from 'views/referidos/Index';
import { Recompensas } from 'views/recompensas/Index';
import { Promociones } from 'views/promociones/Index';
import { Campanias } from 'views/campanias/Index';
import { Actividad } from 'views/actividad/Index';
import { Users } from 'views/users/Index';
import { Correoctes } from 'views/correoclientes/Index';
import { Deptos } from 'views/departamentos/Index';
import { Categori } from 'views/categorias/Index';
import { Etiquetas } from 'views/etiquetas/Index';
import { Articulos } from 'views/articulos/Index';
import { Ventas } from 'views/ventas/Index';
import { Configempresa } from 'views/configuraciones/Index';
import { Slider } from 'views/slider/Index';
import { sliderCliente } from 'views/sliderCliente/Index';

let DashboardComponent = lazy(() => import('./dashboard'));

//const Programa = lazy(() => import('views/puntos'));

//<Route exact path={SLUGS.overview} component={Programa} />
//sucursaladdedit: '/branch/addedit',
////<Route exact path={SLUGS.sucursaladdedit} component={AddEdit} />

function PrivateRoutes() {
    const { pathname } = useLocation();
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path={SLUGS.dashboard} component={DashboardComponent} />
                <Route path={SLUGS.puntos} component={Programa} />
                <Route path={SLUGS.referidosp} component={Referred} />
                <Route path={SLUGS.notificacionp} component={ Correoctes } />
                <Route path={SLUGS.actividadp} component={Actividad} />
                <Route path={SLUGS.ventasp} component={Ventas} />
                <Route path={SLUGS.clientes} component={Clientes} />
                <Route path={SLUGS.estadisticas} component={DashboardComponent}/>
                <Route path={SLUGS.users} component={Users} />
                <Route path={SLUGS.ideasThree} render={() => <div>ideasThree</div>} />
                <Route path={SLUGS.sucursal} component={Sucurs} />
                <Route path={SLUGS.departamentos} component = { Deptos } />
                <Route path={SLUGS.categorias} component={Categori}/>
                <Route path={SLUGS.etiquetas} component={Etiquetas} />
                <Route path={SLUGS.articulos} component={Articulos} />
                <Route path={SLUGS.slider} component={Slider} />
                <Route path={SLUGS.sliderCliente} component={sliderCliente} />
                <Route path={SLUGS.roles} component={Roles} />
                <Route path={SLUGS.promociones} component={Promociones} />
                <Route path={SLUGS.recompensas} component={Recompensas} />
                <Route path={SLUGS.campanias} component={Campanias} />
                <Route path={SLUGS.settings} component={Configempresa} />
                <Route path={SLUGS.subscription} render={() => <div>subscription</div>} />
                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
