import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';

import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import { getDataSession } from 'views/Utils/Common';
// eslint-disable-next-line
import { IconSearch, IconSubscription } from 'assets/icons';

const AddEdit = ({ history, match}) => {  

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const busqueda = useFormInput('');
    let [articulosSearch, setArticulosSearch] = useState([])
    // eslint-disable-next-line
    let [page, setPage] = useState(1)
    // eslint-disable-next-line
    let [totalxPagina, setTotalxPagina] = useState(10)

    const { handleSubmit, reset } = useForm();
    const [canje, setcanje] = useState({});
    const [fileImg, setfileImg] = useState(null);
    const [fechaIni, setfechaIni] = useState("")
    const [fechaFin, setfechaFin] = useState("")

    const [articuloNombre, setArticuloNombre] = useState("")
    const [articuloId, setArticuloId] = useState(null)

    async function buscarArticulos() {
        const response = await  fetchConToken(`catalogos/Articulos/Lista?pagina=${page}&totalxPagina=${totalxPagina}&empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&busqueda=${busqueda.value}`)
        setArticulosSearch(response);

    }

    function onSubmit(data) {
        data.articulo_Id = articuloId
        data.empresa_Id = getDataSession('empresa')
        data.sucursal_Id = getDataSession('sucursal')
        data.fechaInicio = fechaIni
        data.fechaFin = fechaFin
         return isAddMode
            ? createCanje(data)
            : updateCanje(id, data);
    }

    function createCanje(data) {
        return fetchConToken("canjes",data,"POST")
            .then(() => {
                alertService.success('Canje creado correctamente.', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateCanje(id, data) {
        return fetchConToken("canjes", data,"PUT")
            .then((x) => {
                onFileUpload();
                alertService.success('Canje modificado correctamente.', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(e=>alertService.error);
    }

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("canjes?canjeid="+id).then(can => {
                setcanje(can);
                setArticuloId(can.articulo_Id)
                setArticuloNombre(can.nombre)

                const f1 = new Date(can.fechaInicio);
                setfechaIni(f1.toISOString().substr(0,10));

                const f2 = new Date(can.fechaFin);
                setfechaFin(f2.toISOString().substr(0,10));


            });
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(canje);
            // eslint-disable-next-line
        }, [canje]);

        const onFileChange = ({target}) => {
            setfileImg(target.files[0]);
        }

        const onChangeCheck = ({target}) => {
            const xCheck = target.checked ? 'A' : 'I';
            setcanje({
              ...canje,
              [target.name]: xCheck
            });
        }

        const onChangeDateI = (date) => {
            /*setcanje({
              ...canje,
            fechaInicio: date.target.value
            });*/
            setfechaIni(date.target.value);
          }

          const onChangeDateF = (date) => {
           /* setcanje({
              ...canje,
            fechaFin: date.target.value
            });*/
            setfechaFin(date.target.value);
          }


        // On file upload (click the upload button)
    const onFileUpload = () => {
        if(fileImg != null && fileImg.name != null){
            const formData = new FormData();
            formData.append("Canje_Id",id);
            
            formData.append(
            "FileToUpload",
            fileImg,
            fileImg.name
            );
            //console.log(fileImg);
            fetchConToken("canjes/imagen",formData,"POST",true)
            .then(() => {
                alertService.success('Imagen cargada.', { keepAfterRouteChange: true });
                //history.push('..');
            })
            .catch(alertService.error);

        }

      };

    function elejirArticulo(articuloNombre,articulo_id) {
        setArticuloNombre(articuloNombre)
        setArticuloId(articulo_id)
        closeModal()
    }

    const info = () => {
        alertService.error('No se puede cambiar el articulo.');
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        setcanje({
          ...canje,
          [name]: value
        });
    } 

    return (
        <>  
        <div className="container-fluid" >
        <form  onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                <h3>{isAddMode ? 'Agregar Canje' : 'Editar Canje'}</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="submit">
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                         <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                       </svg>
                        &nbsp;
                        Guardar 
                  </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp;
                  </Link>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="articulo_Id" className="form-label">Articulo</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control"  name="articulo" placeholder="articulo" value={articuloNombre}  required disabled/>
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={ isAddMode? openModal : info }><IconSearch/></button>
                    </div>


                    <label htmlFor="fechaInicio" className="form-label">Fecha inicio</label>
                    <input 
                    type="date" 
                    className="form-control" 
                    placeholder="fechaInicio" 
                    value={ fechaIni } 
                    onChange={ onChangeDateI } 
                    name="fechaInicio" />
                    
                    <label htmlFor="fechaFin" className="form-label">Fecha fin</label>
                    <input
                     type="date" 
                     className="form-control" 
                     placeholder="fechaFin" 
                     value={ fechaFin } 
                     onChange={ onChangeDateF } 
                     name="fechaFin" />
                    
                    <label htmlFor="puntos_Requeridos" className="form-label">Puntos requeridos</label>
                    <input
                     type="number" 
                     className="form-control" 
                     name='puntos_Requeridos'
                     placeholder="puntos_Requeridos" 
                     value={canje.puntos_Requeridos}
                     onChange={handleInputChange}
                     required={true}
                     maxLength={20}
                     />

                    <label htmlFor="monto_Requerido" className="form-label">Precio</label>
                    <input 
                    type="number" 
                    className="form-control" 
                    name='monto_Requerido'
                    placeholder="monto_Requerido" 
                    value={canje.monto_Requerido}
                     onChange={handleInputChange}
                    required={true}
                    maxLength={20}
                    />

                </div>
                <div>
            </div>
          </div>
          </div>

          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="canjes_Maximos" className="form-label">Canjes máximos</label>
                    <input 
                    type="number" 
                    className="form-control" 
                    name='canjes_Maximos'
                    placeholder="canjes_Maximos" 
                    required={true}
                    maxLength={20}
                    value={canje.canjes_Maximos}
                    onChange={handleInputChange}
                    />

                    <label htmlFor="num_Canjes" className="form-label">Canjes actuales</label>
                    <input 
                    type="number" 
                    className="form-control" 
                    placeholder="num_Canjes" 
                    name='num_Canjes'
                    required={true}
                    maxLength={20}
                    value={canje.num_Canjes}
                    onChange={handleInputChange}
                    />

                    <label htmlFor="usosxVenta" className="form-label">Canjes por venta</label>
                    <input 
                    type="number" 
                    className="form-control" 
                    placeholder="usosxVenta" 
                    name='usosxVenta'
                    required={true}
                    maxLength={20}
                    value={canje.usosxVenta}
                    onChange={handleInputChange}
                   />

                    <label htmlFor="usosxCliente" className="form-label">Canjes por cliente</label>
                    <input 
                    type="number" 
                    className="form-control" 
                    placeholder="usosxCliente"
                    name='usosxCliente'
                    required={true}
                    maxLength={20}
                    value={canje.usosxCliente}
                    onChange={handleInputChange}
                    />
                    <br/>


                    <div className="form-check form-switch">
                    <input type="checkbox" 
                    className="form-check-input" 
                    name="estatus" 
                    role="switch" 
                    onChange={ onChangeCheck }
                    checked={ canje.estatus === 'A' }
                    />
                        <label htmlFor="chkactivapromo">Habilitado</label>
                    
                    </div>
                    <br/>
                    <div className="col-md-12">
                        <input type="file" className="form-control" name="" onChange={onFileChange}/>
                        <img src={canje.ruta_Imagen} alt=''/>
                    </div>
                </div>
                <div>
            </div>
          </div>
          </div>

          </div>
          </form>
        </div>

        <Modal isOpen={isOpenModal} closeModal={closeModal}>
        <br/>
          <div className="row">
            <div className="col-md-2 tex-left">
                <label>Buscar</label>
            </div>
            <div className="col-md-8 ">
                <input type="text" className="form-control" {...busqueda} />
            </div>
            <div className="col-md-2 text-left">
                <button type="button" className="btn btn-outline-secondary" onClick={()=>buscarArticulos()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
                </button>
            </div>
          </div>
        <table className="table">
                        <thead>
                            <tr>
                            <th></th>
                            <th>Clave</th>
                            <th>Artículo</th>
                            <th>Existencias</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        articulosSearch.map(item=>{
                          return <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.clave}</td>
                                    <td>{item.nombre}</td>
                                    <td>{item.existencias}</td>
                                    <td>
                                        <button type="button" className="btn btn-outline-info" onClick={()=>elejirArticulo(item.nombre, item.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                        })
                        }
                        </tbody> 
        </table>
      </Modal>
        </>
    );    


}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
     
    }
    return {
      value,
      onChange: handleChange
    }
  }

export {AddEdit};