import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { fetchConToken,fetchConTokenFile } from 'helpers/fetch';
// import { IconDownload } from 'assets/icons';
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import  Pagination  from 'components/pagination/Pagination'

import * as FileSaver from "file-saver";

const List = ({ match, saludo, subtitulo}) => {

  const { path } = match;


    const [data, setData] = useState([])
    // const [pagina, setpagina] = useState(1);
    const [busqueda, setbusqueda] = useState("")
    const [firstDay, setfirstDay] = useState(getFirstDay);
    const [lastDay, setlastDay] = useState(getLastDay);
    const [totalxPagina,setTotalxPagina] = useState(10);

    React.useEffect(()=>{
      getData(1);
      // eslint-disable-next-line
    }, []);

    async function getData(page){
      const response = await fetchConToken(`ventas/lista?empresaid=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&pagina=${page}&totalxpagina=${totalxPagina}&fechaInicio=${firstDay}&fechaFin=${lastDay}&busqueda=${busqueda}`);
      //const body = await response.json();
      setData(response);
    } 
    
    const exportToCSV = () => {
      fetchConTokenFile(`Exportar/Excel?empresaid=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&pagina=1&totalxpagina=${totalxPagina}&fechaInicio=${firstDay}&fechaFin=${lastDay}&busqueda=${busqueda}&tipo=VENTAS&columnas=id,fecha,folio,estatus,usuario,importe,puntos_Generados,puntos_Usados,observaciones`,null,'GET',true)
      .then(function(response) {
        return response.blob();
      }).then(function(blob) {
        FileSaver.saveAs(blob, 'ventas.csv');
      })

    }
    const handleChangeDate = ({target}) => {
      if(target.name==='diauno'){
        setfirstDay(target.value);
      }else{
        setlastDay(target.value);
      }

    }

    const handleChangeBusqueda = ({target}) => {
      setbusqueda(target.value);
    }

    
    const handleChangeTotalXpagina = ({target}) => {
      const pagination = target.value;
      setTotalxPagina(pagination);
    }

    return (
        <>
          <div className="container-fluid" >
            <div className="row row mb-3" >
              <div className="col-6 col-sm-3 col-md-3">
                 <input className="form-control" type="date" name="diauno" value={firstDay} onChange={handleChangeDate}/>
              </div> 
              <div className="col-6 col-sm-3 col-md-3">
                 <input className="form-control" type="date" name="diafin" value={lastDay}  onChange={handleChangeDate}/>
              </div>
              <div className="col-6 col-sm-3 col-md-3" style={{textAlign: "right"}}>
                <button className="btn btn-write" onClick={()=> getData(1) }>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                  </svg>
                  &nbsp;
                  Filtrar 
                </button>
              </div>
              <div className="col-6 col-sm-3 col-md-3" style={{textAlign: "right"}}>
                <button className="btn btn-write" onClick={(e) => exportToCSV()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16" style={{color: "#016e38"}}>
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z"/>
                  </svg>
                  &nbsp;
                  Descargar 
                </button>
                &nbsp;
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12">
            <div className="col-8 col-sm-6 col-md-4  mb-3" style={{float: "left",left: "0"}}>
              <div className="input-group" style={{ position: "relative",display: "flex"}}>
                  <label style={{fontSize: "14px"}}>Total por pagina:  &nbsp;</label>
                  <select className="form-control" style={{  width: "100px"}} onChange={handleChangeTotalXpagina}>
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  </div>
              </div>
              <div className="col-4 col-sm-6 col-md-4  mb-3" style={{float: "right",right: "0"}}>
                  <div className="input-group">
                     <input type="text" className="form-control" placeholder="buscar..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={handleChangeBusqueda}/>
                     <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>getData(1)}>
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                     </button>
                  </div>
                </div>
             </div>
             <div className="card col-12 col-sm-12 col-md-12">
          <div style={{overflowX: "auto",maxWidth: "90vw"}}>
            <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Fecha</th>
                      <th>Tipo Doc.</th>
                      <th>Folio</th> 
                      <th>Estatus</th>
                      <th>Cliente</th>
                      <th>Importe</th>
                      <th>P.Generados</th>
                      <th>P.Usados</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                          {data.map(item => {
                          return  <tr key={item.id}>

                            <td>{item.id}</td>
                            <td>{item.fecha}</td>
                            <td>{item.tipo_Doc}</td>
                            <td>{item.folio}</td>
                            <td>{item.estatus}</td>
                            <td>{item.usuario}</td>
                            <td>{item.importe}</td>
                            <td>{item.puntos_Generados}</td>
                            <td>{item.puntos_Usados}</td>
                            <td>{item.observaciones}</td>
                            </tr>

                          })}

                          
                  </tbody>
                </table>
                </div>
                <Pagination consumirPaginacion={getData} elementos={data.length} totalxPagina={totalxPagina}/>
            </div>
            </div>
        </>
    );    


}

List.propTypes = {
    saludo: PropTypes.string
}

List.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { List };