import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';


import fondoCard from 'images/login/image_03.jpg';

const AddEdit = ({ history, match}) => {    

    const path = match.url;
    //console.log(sucursales);
    //const { id } = match.params;
    const { cvecon } = match.params;
    const isAddMode = !cvecon;
    const subIdsAlComprar = [1,2,3,6,7,8];//son subconceptos id en donde se debe mostrar opciones de incremento de puntos, puntos fijos, o %

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

     const { reset } = useForm();
    const [config, setConfig] = useState({});
    const [title, setTitle] = useState("Puntos");
    //const [puntosAsig, setPuntosAsig] = useState(0);

    

    const [pReferir, setpReferir]  = useState(0);
    const [pReferido, setpReferido]  = useState(0);

    const [referir, setReferir] = useState({});
    const [referido, setReferido] = useState({});

    // let puntosReferir = useState(0);
    // let puntosReferido = useState(0);

    const [subconcepto, setSubconcepto] = useState({
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        subconcepto_Id: 0,
        puntos: 0,
        moneda: 0,
        porcentaje: 0,
        limite_Veces: 0,
        limite_Anual: 0,
        estatus: 'A',
        aplica_Forma_Pago: 'N',
        compra_Minima: 0,
        aplica_En_Precios_Con_Dscto: 'N',
        aplica_En_Compras_Con_Puntos: 'N',
        puntos_Minimos_Req: 0,
        puntos_Max_Gastar: 0,
        rango_Entre_Redenciones_Hrs: 0,
        usosxvta: 0,
        subConcepto: 'Porcentaje'
    });
// eslint-disable-next-line
    const {id,empresa_Id,sucursal_Id,subconcepto_Id,puntos,moneda,porcentaje,limite_Veces,limite_Anual,
        // eslint-disable-next-line
        estatus,aplica_Forma_Pago,compra_Minima,aplica_En_Precios_Con_Dscto,aplica_En_Compras_Con_Puntos,
        puntos_Minimos_Req,puntos_Max_Gastar,rango_Entre_Redenciones_Hrs,usosxvta,subConcepto,compra_Maxima = 0} = subconcepto;


        //const [conceptoID, setConceptoID] = useState(0);
    //console.log(errors);

    // function onSubmit(data) {
    //     return createConfig(data);
    // }
    // function onSubmit(data) {
    //     return isAddMode
    //         ? createConfig(data)
    //         : updateConfig(id, data);
    // }

    // function createConfig(data) {
    //     console.log("entrando");
    //     return fetchConToken("configuraciones/conceptos",subConcepto,"POST")
    //         .then(() => {
    //             alertService.success('Configuración creada', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }

    const createConfig = () =>{
        // console.log("entrando");
        if(subconcepto_Id !== 9)
        {
            fetchConToken("configuraciones/conceptos",subconcepto,"POST")
                .then(() => {
                    alertService.success('La configuración se ha guardado correctamente.', { keepAfterRouteChange: true });
                    history.push('..');
                })
                .catch(alertService.error);
        }
        else
        {
            referir.estatus = subconcepto.estatus;
            referido.estatus = subconcepto.estatus;
            fetchConToken("configuraciones/conceptos",subconcepto,"POST")
            .then(() => {
                referir.puntos = pReferir;
                fetchConToken("configuraciones/conceptos",referir,"POST")
                .then(() => {
                    referido.puntos = pReferido;
                    fetchConToken("configuraciones/conceptos",referido,"POST")
                    .then(() => {
                        alertService.success('La configuración se ha guardado correctamente.', { keepAfterRouteChange: true });
                        history.push('..');
                    })
                    .catch(alertService.error);
                })
                .catch(alertService.error);
            })
            .catch(alertService.error);

           

            
            
        }
    }


    // function handleConfig (tipo) {
    //     document.getElementById('lblTextD').append("texto");
    // }

    // function updateConfig(id, data) {
    //     return fetchConToken("configuraciones/conceptos", data,"PUT")
    //         .then(() => {
    //             alertService.success('Configuracion modificada', { keepAfterRouteChange: true });
    //             history.push('..');
    //         })
    //         .catch(alertService.error);
    // }
    

    useEffect(() => {
        //if (!isAddMode) {
            // console.log("Obteniendo data...");
            fetchConToken("Configuraciones/Conceptos?empresaid="+getDataSession('empresa')+"&sucursalid="+getDataSession('sucursal')+"&clave="+cvecon).then(config => { 
                setConfig(config);
                
                let sub = config.subConceptos.find(edo=>edo.estatus === "A");
                if(sub != null){
                    setSubconcepto(sub);
                }else{
                    sub = config.subConceptos[0];
                    setSubconcepto(config.subConceptos[0]);
                }
                setTitulo(sub.subconcepto_Id, sub);
                if(config.clave === 'RF01'){
                setReferir(config.subConceptos[0]);
                setReferido(config.subConceptos[1]);

                setpReferir(config.subConceptos[0].puntos)
                setpReferido(config.subConceptos[1].puntos)
                }

            }).catch(alertService.error);
        //}
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(config);
            // eslint-disable-next-line
        }, [config]);

        const handleInputChange = ({ target }) => {
            setSubconcepto({
                ...subconcepto,
                [target.name]: target.value
            });
        }

        const handleInputChangeP = ({ target }) => {
            setpReferir(target.value);
        }

        const handleInputChangePr = ({ target }) => {
            setpReferido(target.value);
        }

        const handleRdioTipoChange = (tipo) => {

                setTitulo(tipo,subconcepto);

                setSubconcepto({
                    ...subconcepto, 
                    subconcepto_Id: tipo
                });

                // porcentaje: xPorcen,
        }

        function setTitulo(tipo,sbconcepto){
            if(tipo === 1)
                setTitle(`Tus clientes ganarán ${sbconcepto.puntos} puntos por cada $ ${sbconcepto.moneda} gastado.`);

            if(tipo === 2)
                setTitle(`Tus clientes ganarán ${sbconcepto.porcentaje} % de su compra en puntos.`);
            
            if(tipo === 3)
                setTitle(`Tus clientes ganarán ${sbconcepto.puntos} puntos por cada compra realizada.`);

            if(tipo === 6)
                setTitle(`Tus clientes tendrán $ ${sbconcepto.moneda} de descuento en su compra por cada ${sbconcepto.puntos} puntos.`);

            if(tipo === 7)
                setTitle(`Tus clientes tendrán ${sbconcepto.porcentaje} % de descuento en su compra por cada  ${sbconcepto.puntos} puntos.`);

            if(tipo === 8)
                setTitle(`Tus clientes tendrán $ ${sbconcepto.moneda} de descuento en su compra por ${sbconcepto.puntos} puntos.`);

            if(tipo === 4)
                setTitle(`${sbconcepto.puntos} puntos al completar la acción.`);
        }

        const handleCheckChange = ({target}) => {

            const xCheck = target.checked ? 'S' : 'N';
            setSubconcepto({
                ...subconcepto,
                [target.name]: xCheck
              });

        }



    return (
        <>
        <div className="col-md-12 container-fluid" >
            
        <div className="container-fluid row mb-3" >
            <div className="col-md-6" style={{textAlign: "left"}}>
                <h5>{config.nombre}</h5>
            </div>
            <div className="col-md-6" style={{textAlign: "right"}}>
                <button type="button" className="btn btn-outline-success" onClick={ () => { createConfig(subConcepto) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                    </svg>
                    &nbsp;
                    Guardar
                </button>
                &nbsp;
                <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                         <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                         <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                    </svg>
                    &nbsp;
                    Cancelar
                </Link>
            </div>
           
        </div>
                    
           

                   
                <div className="row col-md-12">
                    
                <div className={subIdsAlComprar.includes(subconcepto_Id) ? "row col-md-8" : "row col-md-6" }>
                    <div className="col-md-5 mb-3" style={{display: subIdsAlComprar.includes(subconcepto_Id) ? 'block' : 'none' }}>
                        <div className="card mt-1"  style={{height: "100%"}}>
                        <div className="card-body" >
                                <div className="card-title">
                                    <label className="fs-6 fw-bold">Forma de ganar</label>
                                </div>
                            
                                <div className="form-check mb-3">

                            <div className="form-check form-switch">
                                <input type="checkbox" 
                                    className="form-check-input" 
                                    name="subconcepto_Id" 
                                    role="switch" 
                                    onChange={() => {handleRdioTipoChange(config.sumar=== 'S' ? 3 : 8) }}
                                    checked={subconcepto_Id === 3 || subconcepto_Id === 8}
                                                        />
                                <label htmlFor="subconcepto_Id">Puntos fijos</label> &nbsp;&nbsp;
                            </div>

                                </div>
                                <div className="form-check mb-3" >

                            <div className="form-check form-switch">
                                <input type="checkbox" 
                                    className="form-check-input" 
                                    name="subconcepto_Id" 
                                    role="switch" 
                                    onChange={() => {handleRdioTipoChange(config.sumar=== 'S' ? 2 : 7) }}
                                    checked={subconcepto_Id === 2 || subconcepto_Id === 7}
                                                        />
                                <label htmlFor="subconcepto_Id">Porcentaje de compra</label> &nbsp;&nbsp;
                            </div>

                                </div>
                                <div className="form-check mb-3">

                            <div className="form-check form-switch">
                                <input type="checkbox" 
                                    className="form-check-input" 
                                    name="subconcepto_Id" 
                                    role="switch" 
                                    onChange={() => {handleRdioTipoChange(config.sumar=== 'S' ? 1 : 6) }}
                                    checked={subconcepto_Id === 1 || subconcepto_Id === 6}
                                                        />
                                <label htmlFor="subconcepto_Id">Incremento de puntos</label> &nbsp;&nbsp;
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-7 mt-1 mb-3" id="CondicionesRedimir" style={{display: config.sumar === 'N' ? 'block' : 'none' }}>
                    <div className="card" style={{display: subIdsAlComprar.includes(subconcepto_Id) ? 'block' : 'none' }}>
                    <div className="card-body">
                            <div className="card-title">
                                <label className="fs-6 fw-bold">Excluir información</label>
                            </div>
                                <div className="row col-md-12">
                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Compra minima</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="compra_Minima"
                                            value = {compra_Minima}
                                            onChange={ handleInputChange }/>
                                        </div>

                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Puntos mínimos requeridos</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="puntos_Minimos_Req"
                                            value = {puntos_Minimos_Req}
                                            onChange={ handleInputChange }/>
                                        </div>

                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Puntos máximos a gastar</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="puntos_Max_Gastar"
                                            value = {puntos_Max_Gastar}
                                            onChange={ handleInputChange }/>
                                        </div>
                                        
                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Rango entre redenciones en hrs</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="rango_Entre_Redenciones_Hrs"
                                            value = {rango_Entre_Redenciones_Hrs}
                                            onChange={ handleInputChange }/>
                                        </div>

                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Usos por venta</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="usosxvta"
                                            value = {usosxvta}
                                            onChange={ handleInputChange }/>
                                        </div>

                                        <div className="col-md-8 mb-3">
                                            <label className="fs-6">Compra máxima</label>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="compra_Maxima"
                                            value = {compra_Maxima}
                                            onChange={ handleInputChange }/>
                                        </div>

                                        

                                    </div>

                        </div>
                    </div>
                    </div>

                    <div  className= { subIdsAlComprar.includes(subconcepto_Id) ? (config.sumar === 'N'  ? "col-md-12 mt-1" : "col-md-7 mt-1" ) : "col-md-12 mt-1" } >
                        <div className="card">
                        <div className="row card-body">
                            <div className="card-title">
                                <label className="fs-6 fw-bold">Configuración</label>
                            </div>

                            

                            <div className="col-md-4 mb-3" style={{display: subconcepto_Id === 2 || subconcepto_Id === 7 ? 'block' : 'none' }}>
                                    <label id="lblTextD" className="fs-6">Porcentaje</label>
                                    <input type="number" 
                                    className="form-control align-right" 
                                    name="porcentaje"
                                    value = {porcentaje}
                                    onChange={ handleInputChange }/>
                                </div>


                                <div className="col-md-6 mb-3" style={{display: subconcepto_Id !== 2 && subconcepto_Id !== 9  ? 'block' : 'none' }}>
                                    <label className="fs-6">Puntos</label>
                                    <input type="number" 
                                        className="form-control align-right" 
                                        name="puntos"
                                        value = {puntos}
                                        onChange={ handleInputChange }/>
                                </div>


                                <div className="col-md-12 mb-3" style={{display: subconcepto_Id === 9 ? 'block' : 'none' }}>
                                    <label className="fs-6">Puntos al referir</label>
                                    <input type="number" 
                                        className="form-control align-right" 
                                        value = {pReferir}
                                        onChange={ handleInputChangeP }/>
                                </div>

                                <div className="col-md-12 mb-3" style={{display: subconcepto_Id === 9 ? 'block' : 'none' }}>
                                    <label className="fs-6">Puntos al referido</label>
                                    <input type="number" 
                                        className="form-control align-right" 
                                        value = {pReferido}
                                        onChange={ handleInputChangePr }/>
                                </div>


                                
                                <div className="col-md-6 mb-3" style={{display: subconcepto_Id === 1 || subconcepto_Id === 6 || subconcepto_Id === 8 ? 'block' : 'none' }}>
                                    <label id="lblTextMon" className="fs-6">Moneda</label>
                                    <div className="input-group mb-3">
                                    <span className="input-group-text">$</span>
                                    <input type="text" 
                                    className="form-control" 
                                    value = {moneda} 
                                    name="moneda"
                                    onChange={ handleInputChange }/>
                                    </div>
                                </div>

                                <div className="row col-md-12 mb-3" style={{display: subIdsAlComprar.includes(subconcepto_Id) && config.sumar === 'S' ? 'inherit' : 'none' }}>
                                    <div className="col-md-11">
                                    <label htmlFor="aplica_En_Compras_Con_Puntos" className="fs-6 ">Generar puntos en compras pagadas con puntos:</label>
                                    </div>
                                    <div className="col-md-1">
                                        <input type="checkbox" 
                                            className="form-check-input" 
                                            name="aplica_En_Compras_Con_Puntos" 
                                            role="switch" 
                                            onChange={ handleCheckChange }
                                            checked={ aplica_En_Compras_Con_Puntos === 'S' }
                                            />
                                    </div>
                                    </div>

                                <div style={{display: subIdsAlComprar.includes(subconcepto_Id) ? 'block' : 'none' }}>

                                    <div className="row col-md-12 mb-3">
                                    <div className="col-md-11">
                                    <label htmlFor="aplica_En_Precios_Con_Dscto" className="fs-6 ">Aplicar en compras con descuento:</label>
                                    </div>
                                    <div className="col-md-1">
                                    <input type="checkbox" 
                                    className="form-check-input" 
                                    name="aplica_En_Precios_Con_Dscto" 
                                    role="switch" 
                                    onChange={ handleCheckChange }
                                    checked={ aplica_En_Precios_Con_Dscto === 'S' }
                                    />
                                    </div>
                                    </div>

                                    <div className="row col-md-12 mb-3">
                                        <div className="col-md-8">
                                            <label className="fs-6">Limite veces</label>
                                        </div>
                                        <div className="col-md-4 align-right">
                                            <input type="number" 
                                            className="form-control align-right text-end" 
                                            name="limite_Veces"
                                            value = {limite_Veces}
                                            onChange={ handleInputChange }/>
                                        </div>
                                    </div>

                                    </div>

                                

                            </div>
                        </div>
                    </div>

                   
                </div>

                <div className= { subIdsAlComprar.includes(subconcepto_Id) ? "col-md-4 mt-1" : "col-md-6 mt-1" }>
                    <div className="card mb-3 ">
                    <div className="card-body" style={{backgroundImage: `url(${fondoCard})`,backgroundRepeat: "no-repeat",backgroundSize: "cover"}}>

                        <div className="card-title">
                            <label className="fs-6 fw-bold" style={{ color: "white"}}>Resumen</label>
                        </div>
                        
                            <ul>
                                { config.clave === 'RF01' ?
                                <li className="fs-6" style={{ color: "white"}}>{subIdsAlComprar.includes(subconcepto_Id) ? title : `Se asignaran ${pReferir} puntos al que refere y ${pReferido} al referido.`}</li>
                                :
                                <li className="fs-6" style={{ color: "white"}}>{subIdsAlComprar.includes(subconcepto_Id) ? title : `${puntos} puntos al completar la acción.`}</li>
                                }
                            </ul>
                            <br/>

                        </div>
                    </div>
                    
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="card-title"><label className="fs-6 fw-bold">Estatus</label></div>
                        <div className="form-check">

                        <input className="form-check-input" 
                        type="radio" 
                        name="formaganar1" 
                        checked={estatus === "A"}
                        onChange={ () => {
                            setSubconcepto({
                                ...subconcepto,
                                estatus: 'A'})
                        }
                        }/>
                        <label className="form-check-label fs-6" htmlFor="rbganarenable">
                            Activo
                        </label>
                        </div>
                        <div className="form-check">
                        <input className="form-check-input" 
                        type="radio" 
                        name="formaganar1" 
                        checked={subconcepto.estatus === "I"}
                        onChange={ () => {
                            setSubconcepto({
                                ...subconcepto,
                                estatus: 'I'})
                        }
                        }
                        />
                        <label className="form-check-label fs-6" htmlFor="rbganardisable">
                            Inactivo
                        </label>
                        </div>
                        </div>
                    </div>

                </div>

                

                </div>
                </div>
            
            
            
 

        </>
    );    


}

// const useFormInput = initialValue => {
//     const [value, setValue] = useState(initialValue);
  
//     const handleChange = e => {
//       setValue(e.target.value);
     
//     }
//     return {
//       value,
//       onChange: handleChange
//     }
//   }

export {AddEdit};