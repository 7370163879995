import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';


const CfgEmpresa = ({ history, match, saludo, subtitulo}) => {

  const { path } = match;


    let [data, setData] = useState([''])

    React.useEffect(()=>{
      async function getData(){
        const response = await fetchConToken("empresas?id="+getDataSession('empresa'));
        //const body = await response.json();
        setData(response);
      }
      getData();
    }, []);
    // eslint-disable-next-line
    const {id,
      rfc="",
      razon_Social="",
      telefono_Contacto="",
      email_Contacto="",
      whatsapp_Contacto="",
      es_Config_Sucursal,
      codigo_Postal="",
      pais="",
      // estado,
      municipio="",
      referencia="",
      poblacion="",
      colonia="",
      num_Exterior="",
      num_Interior="",
      // tipo_Persona_Id,
      puntos_Posibles_Canjes=""
    } = data;


    const handleInputChange = ({ target }) => {
      setData({
          ...data,
          [target.name]: target.value
      });
  }

  const onChangeCheck = ({target}) => {
    const xCheck = target.checked ? 'S' : 'N';
    setData({
      ...data,
      [target.name]: xCheck
    });
  }

  const saveInfoempresa = () =>{
    fetchConToken("empresas",data,"PUT")
        .then(() => {
            alertService.success('La cambios se han guardado correctamente.', { keepAfterRouteChange: true, autoClose: true });
            //history.push('.');
        })
        .catch(alertService.error);
  }

    return (
        <>
 <div className="container-fluid" >
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                <h3>Empresa</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="button" onClick={saveInfoempresa}>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                         <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                  </button>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                   <div className="card-title">
                    <h5>Información de tu empresa</h5>
                   </div>

                    <label htmlFor="rfc" className="form-label">RFC</label>
                    <input type="text" className="form-control" name="rfc" value={rfc} onChange={ handleInputChange }/>
                    
                    <label htmlFor="razon_Social" className="form-label">Razón social</label>
                    <input type="text" className="form-control" name="razon_Social" value={razon_Social} onChange={ handleInputChange }/>

                    <label htmlFor="telefono_Contacto" className="form-label">Teléfono contacto</label>
                    <input type="text" className="form-control" name="telefono_Contacto" value={telefono_Contacto} onChange={ handleInputChange }/>

                    <label htmlFor="email_Contacto" className="form-label">Correo contacto</label>
                    <input type="text" className="form-control" name="email_Contacto" value={email_Contacto} onChange={ handleInputChange }/>

                    <label htmlFor="whatsapp_Contacto" className="form-label">WhatsApp</label>
                    <input type="text" className="form-control" name="whatsapp_Contacto" value={whatsapp_Contacto} onChange={ handleInputChange }/>

                    <label htmlFor="codigo_Postal" className="form-label">Código Postal</label>
                    <input type="text" className="form-control" name="codigo_Postal" value={codigo_Postal} onChange={ handleInputChange }/>

                    <label htmlFor="pais" className="form-label">Pais</label>
                    <input type="text" className="form-control" name="pais" value={pais} onChange={ handleInputChange }/>


                    <div className="form-check form-switch">
                        <input type="checkbox" 
                        className="form-check-input" 
                        name="es_Config_Sucursal" 
                        role="switch" 
                        onChange={ onChangeCheck }
                        checked={ es_Config_Sucursal === 'S' }
                        />
                        <label htmlFor="es_Config_Sucursal">¿Tiene configuración por sucursal?</label>
                    </div>

                </div>
                <div>
            </div>
          </div>
          </div>
 
          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                   <div className="card-title">
                    <h5>Datos adicionales</h5>
                   </div>
                    <label htmlFor="municipio" className="form-label">Municipio</label>
                    <input type="text" className="form-control" name="municipio" value={municipio} onChange={ handleInputChange }/>

                    <label htmlFor="referencia" className="form-label">Referencia</label>
                    <input type="text" className="form-control" name="referencia" value={referencia} onChange={ handleInputChange }/>

                    <label htmlFor="poblacion" className="form-label">Población</label>
                    <input type="text" className="form-control" name="poblacion" value={poblacion} onChange={ handleInputChange }/>

                    <label htmlFor="colonia" className="form-label">Colonia</label>
                    <input type="text" className="form-control" name="colonia" value={colonia} onChange={ handleInputChange }/>

                    <label htmlFor="num_Exterior" className="form-label">Num Exterior</label>
                    <input type="text" className="form-control" name="num_Exterior" value={num_Exterior} onChange={ handleInputChange }/>

                    <label htmlFor="num_Interior" className="form-label">Num_Interior</label>
                    <input type="text" className="form-control" name="num_Interior" value={num_Interior} onChange={ handleInputChange }/>

                    <label htmlFor="puntos_Posibles_Canjes" className="form-label">¿A partir de cuantos puntos de diferencia se le muestra a tu cliente sus posibles canjes?</label>
                    <input type="number" className="form-control" name="puntos_Posibles_Canjes" value={puntos_Posibles_Canjes} onChange={ handleInputChange }/>
                </div>
                <div>
            </div>
          </div>
          </div>

          </div> 
        </div>
        </>
    );    


}

CfgEmpresa.propTypes = {
    saludo: PropTypes.string
}

CfgEmpresa.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { CfgEmpresa };