import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { fetchSinToken } from 'helpers/fetch';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';


import logo from 'images/home/Logo.png';
// import fondo from 'images/login/Img_web_07.jpg';

import logoFyttsa from 'images/login/logo_gsj.png';

import fondomovil from 'images/login/banner_movil_login.jpg';

import fondoImg from 'images/login/Img_web_07.jpg';
import cuadradoImg from 'images/login/Img_web_04.jpg';


//import fondoImg from 'images/login/banner_contrasena.jpg';
//import cuadradoImg from 'images/login/cuadrado_contrasena.jpg';

//import logoFyttsa from 'images/login/FYTTSA_LOGO.png';
 


const ForgotPassword = ({ history, saludo, subtitulo}) => {
  var today = new Date();
  var year = today.getFullYear();

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    // const password = useFormInput('');

    const [textoInfo, settextoInfo] = useState('');
    const [textoError, settextoError] = useState('');

    const handleLogin = () => {
      setLoading(true);
      settextoInfo('');
      settextoError('');
      fetchSinToken("Usuarios/RecuperarClave?correo="+username.value)
        .then(response => {
          if(response.length === 0)
             settextoError("Se encontro un error con el correo proporcionado");
          else
          {
            if(response.codigo === 500){
              settextoError(response.mensajes[0]);
            }
            else
            {
              settextoInfo("Se envio un enlace a su correo para la recuperacion de su contraseña");
            }
          }
          setLoading(false);
      }).catch(error => {
          setLoading(false);
          if (error.response.status === 401) 
             settextoError(error.response.data.message);
          else 
            settextoError("Error al recuperar su cuenta.");
      });
    }
    
    

    return (
      <>
      <div className="col-12 col-sm-12 col-md-12 row login" style={{minHeight: "100vh",marginLeft: "0"}}>
      <div className="col-12 col-md-8" style={{backgroundImage: `url("${(Math.abs(window.innerWidth - window.innerHeight) < 200)  ?  cuadradoImg  : fondoImg }")`,backgroundRepeat: "no-repeat", display: window.innerWidth < 768 ? 'none' : 'block',backgroundSize: "cover",backgroundPosition: "center"}}>
         {/* <div className="div-emonedero" >
              <img src={ logo}  />
           </div>
           <div className="text-center" style={{height: "70%"}}>
           <div className="col-md-12" style={{  position: "absolute",top: "20%",width: "60%"}}>
             <h1 style={{color: "#189ad6",fontSize: "3.5vw"}}><b>LA FUERZA DE</b></h1>
             <h3 style={{color: "#ffff",fontSize: "2.5vw"}}>UNA GRAN FAMILIA</h3>
          </div>
              <div style={{ width: "100%",height: "100%"}}>
              
                <div className="col-md-12" style={{  position: "absolute",bottom: "35%",width: "60%"}}>
                  <h3 style={{color: "#ffff"}}><b>Gracias por seguir con el proceso de recuperación de cuenta Fyttsa Club</b></h3>
                  <br/>
                  <p style={{color: "#009CDE",lineHeight: "20px"}}>En el panel podras administrar y gestionar puntos de <br/>recompensa para los clientes de tu negocio</p>
                </div>
              </div>
           </div>*/}
        </div>
        <div className="col-12 col-md-4" style={{backgroundImage: window.innerWidth < 768 ? `url(${fondomovil})` : 'none' ,    backgroundSize: "cover" }}>
        <div className="col-md-12 text-center">
              <Link to={SLUGS.home}>
               <img src={ window.innerWidth < 768 ? logo : logoFyttsa } className="img-fyt" alt='' />
              </Link>
           </div>
        <form style={{ marginBottom: "100px"}}>
          <div style={{marginTop: (window.innerWidth < window.innerHeight)  ? "40px" : (window.innerWidth > 768 ? "40px" : "-12vw" ) }}>
              <div className="col-md-12 text-center">
              <h3 style={{color: window.innerWidth < 768 ? "#ffff" : "#33506f"}}><b>Olvidé mi contraseña</b></h3>
           </div>
           <br/>
           <div className="col-md-12" >
              <div className="col-10 col-md-8 container">
                 <div className="input-group">
                                   <span className="fa fa-user"></span>
                                     <input 
                                     type="text" {...username} 
                                     className="form-control" 
                                     autoComplete="new-password" 
                                     placeholder="Correo" />
                  </div>
             </div>
            <br/>
           </div>                                          
           <div className="col-10 col-md-8 container">
             <label style={{color: "#00f076"}}>{textoInfo}</label>
              <label style={{color: "#ff0000"}}>{textoError}</label>
             <input style={{color: "white", borderRadius: "30px", background:"#189ad6", width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Enviar'} onClick={handleLogin} disabled={loading} /><br />
             <b><a href="/login" className="text-center" style={{ float: "right",color: window.innerWidth < 768 ? "#ffff" : "#16375b"}}> ¿Ya tienes tus datos?</a></b>
           </div>
           </div>
         </form>
         <div className='text-center derechos-reservados' style={{zIndex: "10", position: "absolute",bottom: "0",width: window.innerWidth < 768 ? "95%" : "32%"}}>
            <p className={window.innerWidth < 768 ? 'white' : 'black'} style={{color: "transparent"}}>LEALME</p>
            <p className={window.innerWidth < 768 ? 'white' : 'black'} >{`© ${year} LEALME`}</p>
            <p className={window.innerWidth < 768 ? 'white' : 'black'}>Algunos derechos reservados | Aviso de privacidad</p>
          </div>
        </div>
      </div>
      </>
    );
}



const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}


export { ForgotPassword };