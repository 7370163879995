import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { Clientes } from 'views/home/Clientes';
//import { Home } from 'views/home/index';
import { ForgotPassword } from 'views/usuarios/ForgotPassword';
import { Login } from 'views/usuarios/Login';
import { Register } from 'views/usuarios/Register';
import { ResetPassword } from 'views/usuarios/ResetPassword';
import { ValidarCorreo } from 'views/usuarios/ValidarCorreo';

//import { Login } from 'views/usuarios/Index';
//import { Register } from 'views/usuarios/Register';
//<Route key="resetpassword" exact path={`${SLUGS.resetpassword}/:key`} component={ ResetPassword } />

function PublicRoutes() {
    return (
        <Switch>
            <Route key="login" path={SLUGS.login} component={Login} />
            <Route key="signup" path={SLUGS.signup} component={Register}/>
            <Route key="forgotPassword" exact path={SLUGS.forgotPassword} component={ ForgotPassword } />
            <Route key="resetpassword" exact path={SLUGS.resetpassword} component={ ResetPassword } />
            <Route key="validarcorreo" exact path={SLUGS.validarcorreo} component={ ValidarCorreo } />
            <Route key="clientes" exact path={SLUGS.clientes} component={ Clientes } />
            <Route key="home" exact path={SLUGS.clientes} component={Clientes} />
            <Redirect to={SLUGS.clientes} />
            {/* para start de fyttsa */}
            {/*<Route key="home" exact path={SLUGS.home} component={Home} />
            <Redirect to={SLUGS.home} />*/}
        </Switch>
    );
}

export default PublicRoutes; 
