import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';

const AddEdit = ({ history, match}) => {    

    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    

    const { handleSubmit, reset } = useForm();

    function onSubmit(data) {
        if(data.clave !== data.confirmaClave)
        {
            alertService.error("Las claves no coinciden");
            return;
        }
        return isAddMode 
            ? createSuc(data) 
            : updateSuc(id, data);
    }

    function createSuc(data) {
        return fetchConToken("sucursales",data,"POST")
            .then((response) => {

                if(response.codigo === 500 || response.codigo === 401){
                    alertService.error(response.mensajes[0]);
                    return;
                }

                alertService.success('La sucursal se ha creado correctamente.', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateSuc(id, data) {
        return fetchConToken("sucursales", data,"PUT")
            .then((response) => {
                if(response.codigo === 500 || response.codigo === 401){
                    alertService.error(response.mensajes[0]);
                    return;
                }
                alertService.success('Sucursal modificada correctamente.', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const [sucursal, setSucursal] = useState({
                                                es_Matriz:"N",
                                                empresa_Id: getDataSession('empresa')
                                            });
    // const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("sucursales?id="+id).then(sucursal => {
                // const fields = ['nombre','direccion','telefono','url','ip','usuario','clave','puerto', 'es_Matriz']; 
                setSucursal(sucursal);
            });
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(sucursal);
            // eslint-disable-next-line
        }, [sucursal]);

        const onChangeCheck = ({target}) => {
            const xCheck = target.checked ? 'S' : 'N';
            setSucursal({
              ...sucursal,
              [target.name]: xCheck
            });
        }

        function handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
        
            setSucursal({
              ...sucursal,
              [name]: value
            });
        }



    return (
        <>
         <div className="container-fluid" >
         <form  onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                  <h3>{isAddMode ? 'Agregar Sucursal' : 'Editar Sucursal'}</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                  </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp; 
                  </Link>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nombre" 
                        placeholder="nombre" 
                        value={sucursal.nombre}
                        onChange={handleInputChange}
                        required= {true}
                        maxLength= {100}
                    />
                    <br/>
                    <label htmlFor="direccion" className="form-label">Dirección</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="direccion" 
                        placeholder="direccion" 
                        value={sucursal.direccion}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={300}
                    />
                    <br/>
                    <label htmlFor="telefono" className="form-label">Teléfono</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="telefono" 
                        placeholder="telefono" 
                        value={sucursal.telefono}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={15}
                    />
                    <br/>
                    <label htmlFor="url" className="form-label">URL</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="url" 
                        placeholder="url" 
                        value={sucursal.url}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={100}
                    />

                    <div className="form-check form-switch">

                        <input type="checkbox" 
                        className="form-check-input" 
                        name="es_Matriz" 
                        role="switch" 
                        onChange={ onChangeCheck }
                        checked={ sucursal.es_Matriz === 'S' }
                        />
                        <label htmlFor="es_Matriz">¿Es matriz?</label>

                    </div>

                </div>
                <div>
            </div>
          </div>
          </div>
 
          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="ip" className="form-label">IP</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="ip" 
                        placeholder="ip"
                        value={sucursal.ip}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={50}
                    />
                    <br/>
                    <label htmlFor="usuario" className="form-label">Usuario</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="usuario" 
                        placeholder="usuario" 
                        value={sucursal.usuario}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={50}
                    />
                    <br/>
                    <label htmlFor="clave" className="form-label">Clave</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        name="clave"
                        placeholder="clave"
                        value={sucursal.clave}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={100}
                     />
                    <br/>
                    <label htmlFor="confirmaClave" className="form-label">Confirmar clave</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        name="confirmaClave"
                        placeholder="confirmaClave" 
                        value={sucursal.confirmaClave}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={100}
                    />
                    <br/>
                    <label htmlFor="puerto" className="form-label">Puerto</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="puerto"
                        placeholder="puerto" 
                        value={sucursal.puerto}
                        onChange={handleInputChange}
                        required={true}
                        maxLength={5}
                    />

                </div>
                <div>
            </div>
          </div>
          </div>

          </div> 
          </form>
        </div>
        </>
    );    


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export {AddEdit};