
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';


const AddEdit = ({ history, match}) => {    

    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
     
    const [cliente, setCliente] = useState({});
    const [usuario_Empresa, setUsuario_Empresa] = useState({usuario_Id: 0, empresa_Id: getDataSession('empresa'), sucursal_Id: getDataSession('sucursal'), id_sys: 0});

    let [error, setError] = useState(['']);
    const { register, handleSubmit, reset } = useForm();
    const [fechaNac, setFechaNac] = useState(['']);

    function onSubmit(data) {
        return isAddMode
                ? create(data)
               : update(id, data);         
    }

    function create(data) {
        if(data.sexo !== 'M' && data.sexo !== 'H')
        {
            setError("Seleccione un sexo");
            return;
        }
        return fetchConToken("usuarios/RegistrarApp",data,"POST")
            .then((response) => {
                if (response.hasOwnProperty('codigo')) {
                    alertService.error('Revisar muestra de errores');
                    setError(response.mensajes[0]);
                    return;
                }

                usuario_Empresa.usuario_Id = response.id;
                ligarUsuario(usuario_Empresa);
                activarCuenta(response.id,encodeURIComponent(response.codigo_Referencia));
                alertService.success('Usuario agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function update(id, data) {
        if(data.sexo !== 'M' && data.sexo !== 'H')
        {
            setError("Seleccione un sexo");
            return;
        }
        return fetchConToken("usuarios/Editar", data,"PUT")
            .then(() => {
                alertService.success('Usuario modificado', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    function ligarUsuario(ClienteSys) {
        fetchConToken("usuarios/ClienteSys", ClienteSys,"POST")
            .then((response) => {
                if (response.hasOwnProperty('codigo')) {
                    alertService.error('Revisar muestra de errores');
                    setError(response.mensajes[0]);
                    return;
                }
            })
            .catch(alertService.error);
    }

    function activarCuenta(id_usuario, codigokey) {
        fetchConToken(`usuarios/ValidarCorreo?id=${id_usuario}&key=${codigokey}`, null,"GET")
            .then((response) => {
                if (response.hasOwnProperty('codigo')) {
                    alertService.error('Revisar muestra de errores');
                    setError(response.mensajes[0]);
                    return;
                }
            })
            //.catch(alertService.error);
    }
    

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("usuarios?id="+id).then(response => {
                response.fecha_Nacimiento = response.fecha_Nacimiento ? response.fecha_Nacimiento.substring(0, 10) : '';
                setCliente(response);
            });
        }
        }, []);

        useEffect(() => {
            reset(cliente);
        }, [cliente]);


        function handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
        
            setCliente({
              ...cliente,
              [name]: value
            });
        }  

        const handleChange= (event) => {            
            setCliente({
                ...cliente,
                sexo: event.target.value
              });
        }

    return (
        <>
        <div className="container-fluid" >
          <form  onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                  <h3>{isAddMode ? 'Agregar Cliente' : 'Editar Cliente'}</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                  </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp;
                  </Link>
                </div>
            </div>              
          </div>

          <div className="row">
            <p style={{color: "red"}}>{error}</p>
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nombre" 
                        placeholder="nombre" 
                        required={true}
                        maxLength={20}
                        value={cliente.nombre}
                        onChange={handleInputChange}
                    />
                    
                    <label htmlFor="Apellidop" className="form-label">Apellido Paterno</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="apellidop" 
                        placeholder="apellidop"
                        required={true}
                        maxLength={100}
                        value={cliente.apellidop}
                        onChange={handleInputChange}
                     />

                    <label htmlFor="Apellidom" className="form-label">Apellido Materno</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="apellidom" 
                        placeholder="apellidom"
                        required={true}
                        maxLength={100}
                        value={cliente.apellidom}
                        onChange={handleInputChange}
                     />

                   <label htmlFor="Ocupacion" className="form-label">Ocupacion</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="ocupacion" 
                        placeholder="ocupacion"
                        required={true}
                        maxLength={100}
                        value={cliente.ocupacion}
                        onChange={handleInputChange}
                     />

                    <label htmlFor="Telefono" className="form-label">Telefono</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="telefono" 
                        placeholder="telefono"
                        required={true}
                        maxLength={100}
                        value={cliente.telefono}
                        onChange={handleInputChange}
                     />
                    
                </div>
                <div>
            </div>
          </div>
          </div>
          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <label htmlFor="Correo" className="form-label">Correo</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="correo" 
                        placeholder="correo" 
                        required={true}
                        maxLength={100}
                        value={cliente.correo}
                        onChange={handleInputChange}
                    />
                    
                    <label htmlFor="fecha_Nacimiento" className="form-label">Fecha Nacimiento</label>
                    <input 
                        type="date" 
                        className="form-control" 
                        name="fecha_Nacimiento" 
                        placeholder="fecha_Nacimiento"
                        required={true}
                        maxLength={100}
                        value={cliente.fecha_Nacimiento}
                        onChange={handleInputChange}
                     />

                    <label>Sexo</label>
                    <select value={cliente.sexo} onChange={handleChange}  className={`form-control`} required={true}>
                    <option value={0}>Elije un Sexo</option>
                    <option value='M'>Mujer</option>
                    <option value='H'>Hombre</option>
                    </select>

                   <label htmlFor="Clave" className="form-label">clave</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        name="clave" 
                        placeholder="clave"
                        required={true}
                        maxLength={100}
                        value={cliente.clave}
                        onChange={handleInputChange}
                     />

                    <label htmlFor="claveConfirma" className="form-label">Confirmacion Clave</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        name="claveConfirma" 
                        placeholder="claveConfirma"
                        required={true}
                        maxLength={100}
                        value={cliente.claveConfirma}
                        onChange={handleInputChange}
                     />

                </div>
                <div>
            </div>
          </div>
          </div>

          

          </div> 
          </form>
        </div>
        </>
    );    


}

 
export {AddEdit};