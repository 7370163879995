import React, {useRef,useState} from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import JoditEditor from "jodit-react";
import { alertService } from '_services';

const List = ({ match, saludo, subtitulo}) => {

  const { path } = match;

  const editor = useRef(null)
	const [content, setContent] = useState('')
  const [data, setData] = useState({
    "id": 0,
    "formato_Correo_Id": 0,
    "clave": "",
    "nombre": "",
    "valor": "",
    "habilitado": true
  })
  const [seleccionado, setSeleccionado] = useState('')
  const [mensaje, setMensaje] = useState('')
  
	const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

    async function getConfi(clave){
      setSeleccionado(clave)
      const response = await fetchConToken(`configuraciones/ByClave?empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}&clave=${clave}`)
      if(response !== ''){
         setData(response)
         setContent(response.valor_String);
      }
      else
         setContent("");

         switch(clave) {
            case 'F1-001':
              setMensaje('Las etiquetas que se reemplazaran para envio son: {nombre}, {sucursal}, {productoscanjeados}, {nombreempresa}, {puntosganados}, {puntosgastados}, {canjes}, {folio}, {fecha} ')
            break
            case 'F1-002':
              setMensaje('Las etiquetas que se reemplazaran para envio son: {nombre}, {nombreempresa}, {puntoscumpleanios}, ')
            break
            case 'F1-003':
              setMensaje('Las etiquetas que se reemplazaran para envio son: {nombre}, {puntosganados}, {nombreempresa}')
            break
            case 'F1-004':
              setMensaje('Las etiquetas que se reemplazaran para envio son: {nombre}, {concepto}, {puntos}, {nombreempresa}')
            break

            case 'F2-001':
              setMensaje('')
            break
            case 'F2-002':
              setMensaje('')
            break


            case 'F3-001':
              setMensaje('')
            break


            default:
              setMensaje('')
              break;
        }



    }

    function guardarCorreo() {

      data.clave = seleccionado
      data.nombre = seleccionado
      data.valor = content
      data.habilitado = true

      return fetchConToken(`configuraciones/FormatosCorreo?empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}`,data,"POST")
          .then(() => {
              alertService.success('Configuracion guardada', { keepAfterRouteChange: true });
          })
          .catch(alertService.error);
    }  
 
    return (
        <>
        <div className="col-md-12 container-fluid row" >
            
            <div className="container-fluid row mb-3" >
                <div className="row" style={{textAlign: "left"}}>
                <div className="col-md-6" style={{textAlign: "left"}}>
                <h5>Formatos de correos</h5>
                </div>
                <div className="col-md-6" style={{textAlign: "right"}}> 
                   <button className="btn btn-outline-success" onClick={() => guardarCorreo()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                   </button>
                </div>
            </div> 
            </div>

            <div className="col-md-4" style={{display: "grid"}}>
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title"><b>Generación de puntos</b></h5>
                  <ul className="list-group">
                   <li className="list-group-item fs-6" onClick={()=>getConfi('F1-001')}>Al generar puntos</li>
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F1-002')}>Recompensa de cumpleaños</li>
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F1-003')}>Recompensa al registrarse</li>
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F1-004')}>Ajuste de puntos</li>
                  </ul>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title"><b>Redención de puntos</b></h5>
                  <ul className="list-group">
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F2-001')}>Referido recibe el código</li>
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F2-002')}>EL referido usó el código</li>
                  </ul>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title"><b>Otros</b></h5>
                  <ul className="list-group">
                    <li className="list-group-item fs-6" onClick={()=>getConfi('F3-001')}>Recordatorio por expirar</li>
                  </ul>
                </div>
              </div>
            </div>

           
            <div className="col-md-8">
               <div className='col-md-12'>
                 <label>{mensaje}</label>
              </div>
              <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => {}}
              />
            </div>

           
        </div>
        </>
    );    


}


List.propTypes = {
    saludo: PropTypes.string
}

List.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { List };
