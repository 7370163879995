import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';


const AddEdit = ({ history, match}) => {    

    const path = match.url;
    //console.log(sucursales);
    const { id } = match.params;
    const isAddMode = !id;
    
    

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

    const { register, handleSubmit, reset } = useForm();
    

    function onSubmit(data) {
        return isAddMode
            ? createSuc(data)
            : updateSuc(id, data);
    }

    // function createUser(data) {
    //     return userService.create(data)
    //         .then(() => {
    //             alertService.success('User added', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }

    function createSuc(data) {
        return fetchConToken("sucursales",data,"POST")
            .then(() => {
                alertService.success('Sucursal agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateSuc(id, data) {
        return fetchConToken("sucursales", data,"PUT")
            .then(() => {
                alertService.success('User updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const [sucursal, setSucursal] = useState({});
    //const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("sucursales?id="+id).then(sucursal => {
                // const fields = ['nombre','direccion','telefono','url','ip','usuario','clave','puerto']; 
                //fields.forEach(field => setValue(field, sucursal[field]));
                setSucursal(sucursal);
            });
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(sucursal);
            // eslint-disable-next-line
        }, [sucursal]);



    return (
        <>
        
        <div className="row fs-6">

            <form  onSubmit={handleSubmit(onSubmit)} onReset="">
            <h3>{isAddMode ? 'Agregar Sucursal' : 'Editar Sucursal'}</h3>
                <div className="card">
                    <div className="card-header">
                        <div className="row col-md-12">
                        <div className="col-md-1">
                        <button className="btn btn-danger mr-2" id="btnEliminar" onClick="">Eliminar</button>
                        </div>
                        <div className="col-md-1">
                        <button className="btn btn-outline-success" type="button" id="btnGuardar" onClick="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                </svg>
                                &nbsp;
                                Guardar 
                        </button>
                        </div>

                        <div className="col-md-1">
                        <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancelar</Link>
                        </div>

                        </div>
                        
                    </div>

                    <div className="card-body">
                <div className="row col-md-12">
                <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="empresa" value="1" {...register("empresa_id", {required: true, maxLength: 80})}/>
                    <br/>
                    <input type="text" className="form-control" name="nombre" placeholder="nombre" {...register("nombre", {required: true, maxLength: 80})}/>
                    <br/>
                    <input type="text" className="form-control" placeholder="direccion" {...register("direccion", {required: true, maxLength: 100})}/>
                    <br/>
                    <input type="text" className="form-control" placeholder="telefono" {...register("telefono", {required: true, maxLength: 20})}/>
                    <br/>
                    <input type="text" className="form-control" placeholder="url" {...register("url", {required: true, maxLength: 120})}/>
                </div>

                <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="ip" {...register("ip", {required: true, maxLength: 120})}/>
                    <br/>
                    <input type="text" className="form-control" placeholder="usuario" {...register("usuario", {required: true, maxLength: 120})}/>
                    <br/>
                    <input type="password" className="form-control" placeholder="clave" {...register("clave", {required: true, maxLength: 20})}/>
                    <br/>
                    <input type="password" className="form-control" placeholder="confirmaClave" {...register("confirmaClave", {required: true, maxLength: 20})}/>
                    <br/>
                    <input type="text" className="form-control" placeholder="puerto" {...register("puerto", {required: true, maxLength: 20})}/>

                </div>
                </div>
                </div>
                <div className="card-footer">
                <input type="submit" className="btn btn-primary"/>                  
                    
                </div>
                </div>
            </form>
            
            
        </div>

        </>
    );    


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export {AddEdit};