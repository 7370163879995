import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
//import { useForm } from "react-hook-form";
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import  Pagination  from 'components/pagination/Pagination'

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';


const Detail = ({ history, match}) => {    

    //const { register, handleSubmit, reset, formState } = useForm();
    const path = match.url;

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const { id } = match.params;
    //const isAddMode = !id;

    const [isOpenModal, openModal, closeModal] = useModal(false);

    const [saldosAcum, setsaldosAcum] = useState(0);
    const [saldosAct, setsaldosAct] = useState(0);
    const [saldosUti, setsaldosUti] = useState(0);
    const [programaHabilitado, setProgramaHabilitado] = useState(false)

    const [cliente, setCliente] = useState({});
    const [fecha, setfecha] = useState(new Date().toISOString().substr(0,10))
    const detalles = useFormInput('');
    const puntos = useFormInput('');
    // eslint-disable-next-line
    const [totalxPagina,setTotalxPagina] = useState(10);


    const [ventas, setVentas] = useState([]);
    const [movimientos, setMovimientos] = useState([]);

    const [formPuntos, setFormPuntos] = useState(false);
    const [formDetalles, setDetalles] = useState(false);
    //const [btnShow, setBtnShow] = useState(false);

    function ObtenerVentas(page) {
        fetchConToken(`ventas/Lista?pagina=${page}&totalxPagina=${totalxPagina}&empresaId=${getDataSession('empresa')}&usuarioId=${id}`).then(v => {
            setVentas(v);
        });
    }

    function ObtenerMovimientos(page) {
        fetchConToken(`usuarios/Movimientos/Lista?pagina=${page}&totalxPagina=${totalxPagina}&empresaId=${getDataSession('empresa')}&usuarioId=${id}`).then(m => {
            setMovimientos(m);
        });
    }

    // const ajustarPuntosCte = () => {
    //     return fetchConToken("clientes",null,"POST")
    //         .then(() => {
    //             alertService.success('Cliente agregado', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }


    const excluirDelPrograma = (estatus) => {
        return fetchConToken("Usuarios/CambiarEstatusPrograma?empresaid="+getDataSession('empresa')+"&usuarioid="+id+"&excluir="+estatus, null,"PUT")
            .then(() => {
                alertService.success('Estatus modificado', { keepAfterRouteChange: true });
                setProgramaHabilitado(estatus)
            })
            .catch(alertService.error);
    }

    
    function obtenerUsuario() {
        fetchConToken("usuarios?id="+id+"&empresaid="+getDataSession('empresa')).then(cte => {
            setCliente(cte);
            if(cte.saldoPuntos != null){
                setsaldosAcum(cte.saldoPuntos.puntos_Acumulados);
                setsaldosAct(cte.saldoPuntos.saldo_Actual);
                setsaldosUti(cte.saldoPuntos.puntos_Redimidos);

                //setProgramaHabilitado(cte.saldoPuntos.programa_Habilitado)
            }
        });

        fetchConToken("Usuarios/ClienteSys?empresaId="+getDataSession('empresa')+"&usuarioId="+id).then(cte => {
            setProgramaHabilitado(cte.excluir_Programa === "N" ? false : true)
        });
    }


    useEffect(() => {
        obtenerUsuario()
        ObtenerVentas(1)
        ObtenerMovimientos(1)
        // eslint-disable-next-line
    }, []);

    const onChangeDate = (date) => setfecha(date.target.value);

    const validForms = () =>{
        let band = false;
        if(puntos.value === ""){
            setFormPuntos(true);
        }else{setFormPuntos(false);}
        if(detalles.value === ""){
            setDetalles(true);
        }else{setDetalles(false)}
        if(puntos.value !== "" & detalles.value !== "" && fecha !== ""){
            //setBtnShow(true);
            band=true;
        }
        return band;
    }
    
    const ajustarPuntos = () => {
        const data = {
            "id": 0,
            "fecha": fecha,
            "descripcion": detalles.value,
            "estatus": "A",
            "empresa_Id": getDataSession('empresa'),
            "sucursal_Id": getDataSession('sucursal'),
            "detalles": [
                {
                    "id": 0,
                    "movimiento_Id": 0,
                    "usuario_Id": cliente.id,
                    "usuario": cliente.nombre,
                    "puntos_Asignados": puntos.value
                }
            ]
        }
        if (validForms()) {
            fetchConToken(`usuarios/movimientos`, data, "POST")
                .then(response => {
                    if (response.status === 400) {
                        closeModal();
                        alertService.error(`Ocurrio un error al realizar el ajuste`, { keepAfterRouteChange: true })
                    } else {
                        closeModal()
                        alertService.success('Ajuste realizado con exito', { keepAfterRouteChange: true });
                        obtenerUsuario()
                        ObtenerMovimientos(1)
                    }
                })
                .catch(error =>{
                    closeModal()
                    alertService.error('Ocurrio un error al realizar el ajuste', { keepAfterRouteChange: true })
                });
        }
    }

    
    return (
        <>
        <div className="container-fluid" >
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-md-4">
                  <h4>Detalle del cliente</h4><br/> 
                </div>
                <div className="col-md-4" style={{textAlign: "right"}}>
                  <button className="btn btn-outline-success" type='button' onClick={()=>excluirDelPrograma(!programaHabilitado)}> {programaHabilitado? 'Incluir al programa' : 'Excluir del programa' }</button>
                </div>
                <div className="col-md-4" style={{textAlign: "right"}}>

                  <Link to={'..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp;
                  </Link>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-6">
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <div className="card-title">
                        <label className="fs-6 fw-bold">Información del cliente</label>
                    </div>
                    <label className="fs-4 mb-2">{
                    (cliente.nombre != null ? cliente.nombre : '') + ' ' + 
                    (cliente.apellidop != null ? cliente.apellidop : '')+ ' ' + 
                    (cliente.apellidom != null ? cliente.apellidom : '')}</label>
                        <br/>
                    <a href={`mailto:${cliente.correo}`} className="mt-4">{cliente.correo}</a>
                        <br/>
                    <label className="fs-6 mt-2">Tel. {cliente.telefono}</label>
                        <br/>
                    <label className="fs-6 mt-2">Registrado el: {cliente.fecha_Creacion}</label>
                        <br/>
                    <label className="fs-6 mt-2">Fecha Nac. {cliente.fecha_Nacimiento}</label>
                </div>
                <div>
            </div>
          </div>
          </div>

          <div className="col-md-6">
                <div className="card mb-3 ">
                <div className="card-body text-start">
                   <div className="card-title">
                        <label className="fs-6 fw-bold" >Actividad del cliente</label>
                    </div>

                    <table className="table table-hover">
                        <thead className="">
                            <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Puntos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                movimientos.map(item=>{
                                    return  <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.fecha !=null ? item.fecha.substr(0,10) : ''}</td>
                                                <td>{item.descripcion}</td>
                                                <td>{item.total_Movimiento}</td>
                                            </tr>
                                })
                            }
                           
                        </tbody>
                    </table>
                    <Pagination consumirPaginacion={ObtenerMovimientos} elementos={movimientos.length} totalxPagina={totalxPagina}/>
{/*
                    <div className="col-md-12 text-center">
                        <div className="d-flex justify-content-center">
                            <button className="btn" onClick={antM}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </button>
                            &nbsp;
                            <label>Pagina {pageMovimientos}</label>
                            <button className="btn" onClick={sigM}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                        </div>
</div>*/}
                </div>
                <div>
            </div>
          </div>
          </div>

          <div className="col-md-6">
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <div className="card-title">
                        <label className="fs-6 fw-bold">Puntos Acumulados</label>
                        <br/>
                        <label> <b> {saldosAcum} Puntos </b></label><br/>
                    </div>

                    <div className="card-title">
                        <label className="fs-6 fw-bold">Puntos Utilizados</label>
                        <br/>
                        <label> <b> {saldosUti} Puntos </b></label><br/>
                    </div>

                    <div className="card-title">
                        <label className="fs-6 fw-bold">Sado Actual</label>
                        <br/>
                        <label> <b> {saldosAct} Puntos </b></label><br/>
                    </div>
                    

                    
                    <button type="button" className="btn btn-outline-info" onClick={openModal}>Ajustar puntos</button>
                </div>
                <div>
                  </div>
                  </div>
          </div>

          <div className="col-md-6">
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <div className="card-title">
                        <label className="fs-6 fw-bold">Referidos</label>
                    </div>
                    <label className="fs-5">Link para referir</label><br/>
                    <a href={`${cliente.link_Para_Referir}`}>{cliente.link_Para_Referir}</a>
                </div>
                <div>
                  </div>
                  </div>
          </div>

          <div className="col-md-6">
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <div className="card-title">
                        <label className="fs-6 fw-bold">Ventas</label>
                    </div>
                    <table className="table table-hover">
                      <thead className="">
                          <tr>
                           <th>#</th>
                           <th>Fecha</th>
                           <th>Folio</th>
                           <th>Monto</th>
                           </tr>
                      </thead>
                      <tbody>
                      {ventas.map(item=>{
                          return  <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.fecha!=null ? item.fecha.substr(0,10) : ''}</td>
                                    <td>{item.folio}</td>
                                    <td>{currencyFormat(item.importe!= null ?item.importe : 0 )}</td>
                                </tr>
                      })}
                       
                      </tbody>
                    </table>
                    <Pagination consumirPaginacion={ObtenerVentas} elementos={ventas.length} totalxPagina={totalxPagina}/>
                    {/*
                    <div className="d-flex justify-content-center">
                        <button className="btn" onClick={antV}> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </button>
                        &nbsp;
                        <label>Pagina {pageVentas}</label>
                        <button className="btn" onClick={sigV}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>
                    */}
                    </div>
                <div>
                  </div>
                  </div>
          </div>

          </div>
        </div>

        <Modal isOpen={isOpenModal} closeModal={closeModal}>
          <div className="row">
              <div className="col-md-6">
                  <label htmlFor="fecha" className="form-label">Fecha</label>
                  <input type="date" className="form-control" {...fecha} onChange={ onChangeDate } required={true}/>
                  {fecha ==="" ? <p style={{color:'red'}}>Campo obligatorio</p>: ("")}
              </div>
              <div className="col-md-6">
                  <label htmlFor="fecha" className="form-label">Descripción</label>
                  <input type="text" className="form-control"  {...detalles} required={true}/>
                  {formDetalles && <p style={{color:'red'}}>Campo obligatorio</p>}
              </div>
              <div className="col-md-12">
                  <label htmlFor="fecha" className="form-label">Ajuste de puntos</label>
                  <input type="number" className="form-control"  {...puntos} required={true} />
                  {formPuntos && <p style={{color:'red'}}>Campo obligatorio</p>}
              </div>
              <div className="col-md-12 text-right">
              <div >
              <br/>
                <button className="btn btn-outline-success" type="button" onClick={ajustarPuntos}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            &nbsp;
                            Guardar 
                </button>
               </div> 
               </div>

          </div>
      </Modal>

        </>
    );    


}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
     
    }
    return {
      value,
      onChange: handleChange
    }
  }


export {Detail};