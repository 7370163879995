import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken } from 'helpers/fetch';

import fytsaisologo from 'images/panel/login/Fyttsa_isologo.png';
import ilustracionlogin from 'images/panel/login/Illustration_login.png';
//import fyttsalogo from 'images/panel/login/Fyttsa_logo.png';
import fyttsalogo from 'images/panel/login/logo_gsj.png';
import emonederologo from 'images/panel/login/eMonedero_logo.png';
import backgroundimg from 'images/panel/login/background_login.png';

import './Login.css';

const Register = ({ history, saludo, subtitulo}) => {

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);
  
    // handle button click of login form
    const handleLogin = () => {
      setError(null);
      setLoading(true);

      fetchSinToken("Usuarios/Login",{ usuario: username.value, clave: password.value },"POST").then(response => {
        setLoading(false);
        setUserSession(response.token, response.userName);
        setDataSession('empresa',1);
        setDataSession('sucursal',1);
        history.push('/dashboard');
    }).catch(error => {
        setLoading(false);
        if (error.response.status === 401) setError(error.response.data.message);
        else setError("Error al iniciar sesión. Por favor, intente más tarde.");
    });


    }



    return (

        <>
        <div className="row col-md-12">
            
            <div className="col-md-7">
                <img src={backgroundimg} className="img-responsive" style={{position: 'absolute'}} alt=''/>
              <div className="row col-md-12 d-flex justify-content-start">
                <img src={emonederologo} className="img-responsive emonederologo" alt=''/>
              </div>

              <div className="row-col-md-12 text-center d-flex justify-content-center">
                <img src={ilustracionlogin} className="img-responsive img-ilustracion1" alt=''/>
              </div>
              <div className="row-col-md-12 text-center d-flex justify-content-center mt-2">
                <img src={fyttsalogo} className="img-responsive fyttsalogo" alt=''/>
              </div>
              <div className="row col-md-12 text-center d-flex justify-content-center mt-5">
                <h4 className="textprimary">Bienvenido a eMonedero </h4>
              </div>

              <div className="row col-md-8 offset-md-2 text-center d-flex justify-content-center mt-4">
                <p className="textsecond">En este panel podras administrar y gestionar puntos de recompensa para los clientes de tu negocio </p>
              </div>

              
            </div>

            <div className="col-md-5 text-center fondo-form">
                        
                <fieldset className="clearfix">

                <div className="card-body col-md-12 text-center ">
                  <div className="col-md-2 offset-md-5 d-flex justify-content-center mt-4">
                    <img src={ fytsaisologo } className="img-responsive " alt=''/>
                  </div>
                  <br/>
                  <br/>
                <h3>Registro de nueva cuenta</h3>
                <h5>Captura tus datos</h5>
                <br/>
                
                <div className="col-md-6 offset-md-3">
                <label htmlFor="" className="form-label d-flex justify-content-start">Nombre</label>
                      <div className="input-group">
                      <span className="fa fa-user"></span>
                        <input 
                        type="text" {...username} 
                        className="form-control" 
                        autoComplete="new-password" 
                        placeholder="Correo" />
                        </div>
                </div>
                <br/>

                <div className="col-md-6 offset-md-3">
                <label htmlFor="" className="form-label d-flex justify-content-start">Correo</label>
                      <div className="input-group">
                      <span className="fa fa-user"></span>
                        <input 
                        type="text" {...username} 
                        className="form-control" 
                        autoComplete="new-password" 
                        placeholder="Correo" />
                        </div>
                </div>
                <br/>


                <div className="col-md-6 offset-md-3">
                <label htmlFor="" className="form-label d-flex justify-content-start">Correo</label>
                      <div className="input-group">
                      <span className="fa fa-user"></span>
                        <input 
                        type="text" {...username} 
                        className="form-control" 
                        autoComplete="new-password" 
                        placeholder="Correo" />
                        </div>
                </div>
                <br/>

                    
                <div className="col-md-6 offset-md-3">
                <label htmlFor="" className="form-label d-flex justify-content-start">Clave</label>
                    <div className="input-group">
                    <span className="fa fa-lock"></span>
                    <input 
                    type="password" {...password} 
                    className="form-control" 
                    autoComplete="new-password" 
                    placeholder="Clave" />
                    </div>
                </div>
                    

                    </div>

                    <div className="card-footer">
                      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                      <input type="button" className="btn btn-info button-login" value={loading ? 'Cargando...' : 'Ingresar'} onClick={handleLogin} disabled={loading} /><br /><br/>
                      <a href="forgotpassword" className="text-center colorplaceholder"> ¿Olvidaste tu clave?</a>       <br/><br/>
                      <label className="fs-6 textsecond">¿Ya tienes cuenta?</label><br/>
                      <Link to="./login" className="btn btn-info button-second">Ingresar</Link>
                    </div>

                </fieldset>

            </div>

        

      </div>
        </>
    );
}


const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }


  Register.propTypes = {
    saludo: PropTypes.string
}

Register.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { Register };