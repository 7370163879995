import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from 'views/puntos/AddEdit';
import { Puntos } from './Puntos';


function Programa({ match }) {
    const { path } = match;

    //console.log(`path- ${match.url}/add`);
    //<Route path={`${path}/edit/:id`} component={AddEdit} />

    return (
        <Switch>
            <Route exact path={path} component={Puntos} />
            <Route path={`${path}/add/:cvecon`} component={AddEdit} />
            <Route path={`${path}/edit/:cvecon`} component={AddEdit} />
            <Redirect from="*" to={{
                                pathname: Puntos
                            }}/>
        </Switch>
    );
}


export { Programa };

//export default Sucurs;