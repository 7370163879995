import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';


const AddEdit = ({ history, match}) => {    

    // const path = match.url;
    //console.log(sucursales);
    const { id } = match.params;
    const isAddMode = !id;
    
    

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

    const { handleSubmit, reset } = useForm();

    function onSubmit(data) {
        return isAddMode
            ? createSuc(data)
            : updateSuc(id, data);
    }

    // function createUser(data) {
    //     return userService.create(data)
    //         .then(() => {
    //             alertService.success('User added', { keepAfterRouteChange: true });
    //             history.push('.');
    //         })
    //         .catch(alertService.error);
    // }

    function createSuc(data) {
        return fetchConToken("sucursales",data,"POST")
            .then(() => {
                alertService.success('Sucursal agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateSuc(id, data) {
        return fetchConToken("sucursales", data,"PUT")
            .then(() => {
                alertService.success('User updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const [sucursal, setSucursal] = useState({});
    //const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("sucursales?id="+id).then(sucursal => {
                //const fields = ['nombre','direccion','telefono','url','ip','usuario','clave','puerto']; 
                //fields.forEach(field => setValue(field, sucursal[field]));
                setSucursal(sucursal);
            });
        }
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(sucursal);
            // eslint-disable-next-line
        }, [sucursal]);



    return (
        <>
        
        <div className="row fs-6">

            <form  onSubmit={handleSubmit(onSubmit)} onReset="">
            <h3>{isAddMode ? 'Nuevo cupón de descuento' : 'Editar cupón de descuento'}</h3>
                <div className="card">
                    

                    <div className="card">
                <div className="row col-md-12">
                    
                <div className="col-md-4 mt-1">
                    <div className="card">
                        <div className="card-header">
                            <label>Valor de recompensa</label>
                        </div>
                        <div className="card-body">
                        <label>Valor de descuento</label>
                            <input type="number" className="form-control align-right"/>
                        </div>
                    </div>

                    <br/>
                    <div className="card">
                        <div className="card-header">
                            <label>Configuración</label>
                        </div>

                        <div className="card-body">
                            <label>Monto mínimo de compra requerido</label>
                            <input type="number" className="form-control align-right"/>

                        </div>
                    </div>
                </div>

                <div className="col-md-4 mt-1">
                <div className="card">
                    <div className="card-header">
                        <label>Excluir información</label>
                    </div>
                    <div className="card-body">
                        <label>Excluir departamentos id's</label>
                        <textarea type="text" className="form-control" placeholder="id's separados por comas"/>
                        <br/>
                        <label>Excluir categorías id's</label>
                        <textarea type="text" className="form-control" placeholder="id's separados por comas"/>
                        <br/>
                        <label>Excluir artículos id's</label>
                        <textarea type="text" className="form-control" placeholder="id's separados por comas"/>
                        <br/>
                        <label>Excluir etiquetas</label>
                        <textarea type="text" className="form-control" placeholder="etiquetas separados por comas"/>
                        <br/>

                    </div>
                </div>
                </div>


                <div className="col-md-4 mt-1">
                    <div className="card">
                        <div className="card-header">
                            <label>Resumen</label>
                        </div>
                        <div className="card-body">

                            <ul>
                                <li className="fs-6">$5.00 aplica en toda la orden.</li>
                                <li className="fs-6">Aplica en órdenes de cualquier monto.</li>
                            </ul>
                            <br/>

                        </div>
                    </div>
                    <br/>
                    <div className="card">
                        <div className="card-header"><label>Estatus</label></div>
                        <div className="card-body">
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="formaganar1" id="rbganarenable"/>
                        <label className="form-check-label" htmlFor="rbganarenable">
                            Activo
                        </label>
                        </div>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="formaganar1" id="rbganardisable" checked/>
                        <label className="form-check-label" htmlFor="rbganardisable">
                            Inactivo
                        </label>
                        </div>
                        </div>
                    </div>

                </div>

                

                </div>

                </div>
                <div className="card-footer mt-1">
                <input type="submit" className="btn btn-primary" value="Guardar"/> &nbsp;
                <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-info">Cancelar</Link>
                </div>
                </div>
            </form>
            
            
        </div>

        </>
    );    


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export {AddEdit};