import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { fetchSinToken } from 'helpers/fetch';

//import fyttsa_club from 'images/home/FYTTSA_CLUB_LOGO_02.png';
import fyttsa_club from 'images/login/emonedero.png';
import imgmonedero from 'images/home/Illustration_01.jpg';


export default function SuscripcionComponent(props) {

  // eslint-disable-next-line
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const nombre = useFormInput('');
  const apellidop = useFormInput('');
  const apellidom = useFormInput('');
  const correo = useFormInput('');
  const telefono = useFormInput('');
  const clave = useFormInput('');
  const claveConfirma = useFormInput(''); 

  const [textoInfo, settextoInfo] = useState('');
  const [textoError, settextoError] = useState('');

  const [data, setData] = useState(null);

   const agregar = () => {
    console.log("entro")
    console.log(nombre)
    setData(
        { ...data,
          id: 0,
          nombre: nombre.value,
          apellidop: apellidop.value,
          apellidom: apellidom.value,
          correo: correo.value,
          telefono: telefono.value,
          clave: clave.value,
          claveConfirma: claveConfirma.value
    }
    )

    settextoInfo('');
    settextoError('');
    if(nombre === null ||nombre.value === null || nombre.value === '')
    {
      settextoError("El campo nombre no puede estar vacio") ;
      return;
    }
    if(apellidop === null ||apellidop.value === null||apellidop.value === '')
    {
      settextoError("El campo apellido no puede estar vacio") ;
      return;
    }
    if(apellidom === null ||apellidom.value === null||apellidom.value === '')
    {
      settextoError("El campo apellido no puede estar vacio") ;
      return;
    }
    if(correo === null ||correo.value === null|correo.value === '')
    {
      settextoError("El campo correo no puede estar vacio") ;
      return;
    }
    if(telefono === null ||telefono.value === null||telefono.value === '')
    {
      settextoError("El campo telefono no puede estar vacio") ;
      return;
    }
    if(clave === null ||clave.value === null|clave.value === '')
    {
      settextoError("El campo contraseña no puede estar vacio") ;
      return;
    }
    if(claveConfirma === null ||claveConfirma.value === null||claveConfirma.value === '')
    {
      settextoError("El campo confirmacion de contraseña no puede estar vacio") ;
      return;
    }
    if(clave.value !== claveConfirma.value)
    {
      settextoError("Las contraseñas no coinciden") ;
      return;
    }


    var usuario = {
      id: 0,
      nombre: nombre.value,
      apellidop: apellidop.value,
      apellidom: apellidom.value,
      correo: correo.value,
      telefono: telefono.value,
      clave: clave.value,
      claveConfirma: claveConfirma.value
    }


    console.log(data)
    fetchSinToken("Usuarios/RegistrarApp",usuario,'POST').then(x=>{
      if(x.codigo === 500){
        settextoError(x.mensajes[0]) ;
      }
      else
      {
        settextoInfo("Se registro correctamente. Confirme su correo para activar su cuenta");
      }

        
    })
    
  };

  return(
      <div style={{padding: "20px"}} >
    <div className="col-md-12 suscripcion row">
       <div className="col-md-9 row">
        <div className='col-5 col-sm-5 col-md-5'>
        <img src={imgmonedero} alt='' style={{    height: "auto", width: "100%"}} />
        </div>

        <div className='col-7 col-sm-7 col-md-7 text-center'>
            <h1 className="titulo1 label-bold"><b>¡Ahorra y  <label className="text-azul">gana más!</label></b></h1>
            <h1 className="titulo1 label-bold"><b>con</b></h1>
            <br/>
            <img className='fyttsa-club' alt='' src={fyttsa_club} style={{    height: "30px", width: "auto", display: "initial"}} />
            <br/>
            <br/>
       <h1 className="titulo1 label-bold">Suscríbete y obtén</h1>

       <h1 className="titulo1 text-azul h1-suscripcion-cantidad label-bold" style={{fontSize: "10vw"}}>{props.puntos}</h1>
       <h1 className="titulo1 text-azul h1-suscripcion-puntos label-bold" style={{fontSize: "5vw"}}>PUNTOS</h1>

         </div>
        
       


       </div>
       <div className="col-md-3">
            <div className="col-md-12">
            <input className="form-control" {...nombre} placeholder='Nombre'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" {...apellidop} placeholder='Apellido Paterno'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" {...apellidom} placeholder='Apellido Materno'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" {...telefono} placeholder='Número de teléfono'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" type="email" {...correo} placeholder='Dirección de correo electrónico'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" type="password" {...clave} placeholder='Contraseña'></input>
            <br/>
            </div>
            <div className="col-md-12">
            <input className="form-control" type="password" {...claveConfirma} placeholder='Confirmar contraseña'></input>
            <br/>
            </div>
            <div className=" col-md-12 text-center">
              <label style={{color: "#00f076"}}>{textoInfo}</label>
              <label style={{color: "#ff0000"}}>{textoError}</label>
            <button className="btn btn-suscribete-ahora" type='button' onClick={agregar}> 
               ¡Suscríbete GRATIS!
              </button>
              </div>
       </div>
    </div>
    </div>
  )
}
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}