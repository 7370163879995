import React from 'react';
// import PropTypes from 'prop-types';

// import { fetchSinToken, fetchConToken } from 'helpers/fetch';

import fondocolmd4 from 'images/login/Image_02.jpg';

import logoFyttsa from 'images/home/Logo_sj.png';
//import logoFyttsa from 'images/login/Logo_01.png';

import logoElefante from 'images/login/Image_01.png';

import logoFace from 'images/login/Icon_01.png';
import logoInsta from 'images/login/Icon_02.png';
import logoTwitter from 'images/login/Icon_03.png';
import logoIn from 'images/login/Icon_04.png';
import logoYou from 'images/login/Icon_05.png';

import emonedero from 'images/login/emonedero.png';
// import { alertService } from '_services';

const ValidarCorreo = ({ history, location }) => {

    // const [loading, setLoading] = useState(false);
    // const password = useFormInput('');
    //const { key } = match.params;
    const query = new URLSearchParams(location.search);//.get("id");
    const msgError = query.get('error')

    // const [error, setError] = useState(null);
    

    return (
      <>
     

      <div className="col-md-12 row login" style={{minHeight: "100vh",marginLeft: "0"}}>
        <div className="col-12 col-md-8" style={{display: window.innerWidth < 768 ? 'none' : 'block'}}>
          <div className="div-emonedero" >
              <img src={emonedero} alt='' />
           </div>
           <div className="text-center" style={{height: "70%"}}>
           <div className="col-md-12" style={{  position: "absolute",top: "20%",width: "60%"}}>
             <h1 style={{color: "#003869",fontSize: "3.5vw"}}><b>LA FUERZA DE</b></h1>
             <h3 style={{color: "#6F6F6E",fontSize: "2.5vw"}}>UNA GRAN FAMILIA</h3>
          </div>
              <div style={{backgroundImage: `url(${logoElefante})`, width: "100%",height: "100%",backgroundRepeat: "no-repeat",    backgroundSize: "45%,300px",backgroundPositionY: "bottom"}}>
              
                <div className="col-md-12" style={{  position: "absolute",bottom: "40%",width: "60%"}}>
                  <h4 style={{color: "#003869"}}><b>Bienvenido a eMonedero </b></h4>
                  <label style={{color: "#009CDE"}}>Control y gestion de puntos y recompensas</label>
                </div>
                <div className="col-md-12 text-center login-icons" style={{  position: "absolute",bottom: "30px",width: "60%"}}>
                  <img src={logoFace} alt=''/>   &nbsp;
                  <img src={logoInsta} alt=''/>   &nbsp;
                  <img src={logoTwitter} alt=''/>   &nbsp;
                  <img src={logoIn} alt=''/>   &nbsp; 
                  <img src={logoYou} alt=''/>  &nbsp;
                </div>
              </div>
              

              
           </div>
        </div>
        <div className="col-12 col-md-4" style={{backgroundImage: `url(${fondocolmd4})`}}>
        
            
              
              <div className="col-md-12 text-center">
              <img src={logoFyttsa} className="img-fyt" alt='' />
              <h2 style={{color: "white"}}><b>{ msgError ? "Surgió un error al activar la cuenta" : "Cuenta activada correctamente" }</b></h2>
              <label style={{color: "white"}}> { msgError ? msgError : "Ahora puede usar tu cuenta desde la app." } </label>
           </div>
           
           
           
        </div>
      </div>
      </>
    );
}



// const useFormInput = initialValue => {
//   const [value, setValue] = useState(initialValue);

//   const handleChange = e => {
//     setValue(e.target.value);
   
//   }
//   return {
//     value,
//     onChange: handleChange
//   }
// }


export { ValidarCorreo };