import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';

const AddEdit = ({ history, match}) => {    

    const path = match.url;
    //console.log(sucursales);
    const { id } = match.params;
    const isAddMode = !id;
    const [tipos,setTipos] = useState([]);
    

    // functions to build form returned by useForm() hook
    //const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
    //    resolver: yupResolver(validationSchema)
    //});

    const { register, handleSubmit, reset } = useForm();
    

    function onSubmit(data) {
        return isAddMode
            ? createSuc(data)
            : updateSuc(id, data);
    }

    async function buscarTiposCampana() {
        const response = await  fetchConToken(`TipoCampana/Lista?pagina=1&totalxPagina=30&empresaId=${getDataSession('empresa')}&sucursalId=${getDataSession('sucursal')}`)
        setTipos(response);

    }

    function createSuc(data) {
        return fetchConToken("campanas",data,"POST")
            .then(() => {
                alertService.success('Sucursal agregado', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }


    function updateSuc(id, data) {
        return fetchConToken("sucursales", data,"PUT")
            .then(() => {
                alertService.success('User updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(alertService.error);
    }

    const [sucursal, setSucursal] = useState({
        estatus: 'A'
    });
    //const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken("sucursales?id="+id).then(sucursal => {
                //const fields = ['nombre','direccion','telefono','url','ip','usuario','clave','puerto']; 
                //fields.forEach(field => setValue(field, sucursal[field]));
                setSucursal(sucursal);
            });
        }
        buscarTiposCampana()
        // eslint-disable-next-line
    }, []);

        // effect runs when user state is updated
        useEffect(() => {
            // reset form with user data
            reset(sucursal);
            // eslint-disable-next-line
        }, [sucursal]);

        const onChangeCheck = ({target}) => {
            const xCheck = target.checked ? 'A' : 'I';
            setSucursal({
              ...sucursal,
              [target.name]: xCheck
            });
        }


    return (
        <> 
        <div className="container-fluid" >
        <form  onSubmit={handleSubmit(onSubmit)} onReset="">
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                  <h3>{isAddMode ? 'Agregar campaña' : 'Editar campaña'}</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="submit">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                     </svg>
                        &nbsp;
                        Guardar 
                  </button>
                  &nbsp;
                  <Link to={isAddMode ? '.' : '..'} className="btn btn-outline-warning"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-reply" viewBox="0 0 16 16">
                        <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"/>
                    </svg> 
                    &nbsp;
                    Regresar 
                    &nbsp; 
                  </Link>
                </div>
            </div>              
          </div>
          <div className="row">
            <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                    <input type="hidden" className="form-control"  value={getDataSession('empresa')} {...register("empresa_Id", {required: true, maxLength: 80})}/>
                    <input type="hidden" className="form-control"  value={getDataSession('sucursal')} {...register("sucursal_Id", {required: true, maxLength: 80})}/>

                    <label htmlFor="tipo_campana_id" className="form-label">Tipo campaña</label>
                    <select className="form-control" {...register("tipo_campana_id", {required: true, maxLength: 80})}>
                        <option key={0} value="0">Elegir...</option>
                        {tipos.map(item => {
                           return  <option key={item.id} value={item.id}>{item.nombre}</option>
                        })}
                    </select>

                    <br/>
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input type="text" className="form-control" name="nombre" placeholder="nombre" {...register("nombre", {required: true, maxLength: 80})}/>
                    <br/>
                    <div className="form-check form-switch">
                        <input type="checkbox" 
                        className="form-check-input" 
                        name="estatus" 
                        role="switch" 
                        onChange={ onChangeCheck }
                        checked={ sucursal.estatus === 'A' }
                        />
                        <label htmlFor="chkactivapromo">Activar campaña</label>
                    </div>

                </div>
                <div>
            </div>
          </div>
          </div>
          <div className="col-md-6" style={{display: "grid"}}>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                   <label htmlFor="fecha_inicio" className="form-label">Fecha Inicio</label>
                    <input type="date" className="form-control"  {...register("fecha_inicio", {required: true, maxLength: 100})}/>
                    <br/>
                    <label htmlFor="fecha_fin" className="form-label">Fecha Fin</label>
                    <input type="date" className="form-control"  {...register("fecha_fin", {required: true, maxLength: 20})}/>
                    <br/>
                    
                </div>
                <div>
            </div>
          </div>
          </div>
          </div>
          </form>
        </div>
        </>
    );    


}

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export {AddEdit};